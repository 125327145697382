import {
  format,
  addDays,
  endOfMonth,
  subMonths,
  startOfQuarter,
  endOfQuarter,
  getQuarter,
  subQuarters,
  startOfYear,
  endOfYear,
  getYear,
  subYears
} from 'date-fns';

const now = new Date();
export const todayFormatted = format(now, 'YYYY-MM-DD 00:00:00');
const endOfTwoMonthsAgo = endOfMonth(subMonths(now, 2));
const oneMonthAgo = subMonths(now, 1);

export const dateRanges = {
  last12Months: {
    start: format(subYears(now, 1), 'YYYY-MM-DD 00:00:00'),
    end: todayFormatted,
    shortcut: 'last12months'
  },
  yearFromEndOfTMA: {
    start: format(
      addDays(subYears(endOfTwoMonthsAgo, 1), 1),
      'YYYY-MM-DD 00:00:00'
    ), // have to subtract one day because dates are inclusive
    end: format(endOfTwoMonthsAgo, 'YYYY-MM-DD 00:00:00'),
    shortcut: 'yearFromEndOfTMA'
  },
  lastQuarterFromOMA: {
    start: format(
      startOfQuarter(subQuarters(oneMonthAgo, 1)),
      'YYYY-MM-DD 00:00:00'
    ),
    end: format(
      endOfQuarter(subQuarters(oneMonthAgo, 1)),
      'YYYY-MM-DD 00:00:00'
    ),
    year: getYear(subQuarters(oneMonthAgo, 1)),
    shortcut: 'lastquarter',
    label:
      'Q' +
      getQuarter(subQuarters(oneMonthAgo, 1)) +
      ' ' +
      getYear(subQuarters(oneMonthAgo, 1))
  },
  twoQuarterFromOMA: {
    start: format(
      startOfQuarter(subQuarters(oneMonthAgo, 2)),
      'YYYY-MM-DD 00:00:00'
    ),
    end: format(
      endOfQuarter(subQuarters(oneMonthAgo, 2)),
      'YYYY-MM-DD 00:00:00'
    ),
    year: getYear(subQuarters(oneMonthAgo, 2)),
    shortcut: '2quarterago',
    label:
      'Q' +
      getQuarter(subQuarters(oneMonthAgo, 2)) +
      ' ' +
      getYear(subQuarters(oneMonthAgo, 2))
  },
  threeQuarterFromOMA: {
    start: format(
      startOfQuarter(subQuarters(oneMonthAgo, 3)),
      'YYYY-MM-DD 00:00:00'
    ),
    end: format(
      endOfQuarter(subQuarters(oneMonthAgo, 3)),
      'YYYY-MM-DD 00:00:00'
    ),
    year: getYear(subQuarters(oneMonthAgo, 3)),
    shortcut: '3quarterago',
    label:
      'Q' +
      getQuarter(subQuarters(oneMonthAgo, 3)) +
      ' ' +
      getYear(subQuarters(oneMonthAgo, 3))
  },
  fourQuarterFromOMA: {
    start: format(
      startOfQuarter(subQuarters(oneMonthAgo, 4)),
      'YYYY-MM-DD 00:00:00'
    ),
    end: format(
      endOfQuarter(subQuarters(oneMonthAgo, 4)),
      'YYYY-MM-DD 00:00:00'
    ),
    year: getYear(subQuarters(oneMonthAgo, 4)),
    shortcut: '4quarterago',
    label:
      'Q' +
      getQuarter(subQuarters(oneMonthAgo, 4)) +
      ' ' +
      getYear(subQuarters(oneMonthAgo, 4))
  },
  lastYearFromOMA: {
    start: format(startOfYear(subYears(oneMonthAgo, 1)), 'YYYY-MM-DD 00:00:00'),
    end: format(endOfYear(subYears(oneMonthAgo, 1)), 'YYYY-MM-DD 00:00:00'),
    year: getYear(subYears(oneMonthAgo, 1)),
    shortcut: 'lastyear',
    label: getYear(subYears(oneMonthAgo, 1))
  },
  twoYearsFromOMA: {
    start: format(startOfYear(subYears(oneMonthAgo, 2)), 'YYYY-MM-DD 00:00:00'),
    end: format(endOfYear(subYears(oneMonthAgo, 2)), 'YYYY-MM-DD 00:00:00'),
    year: getYear(subYears(oneMonthAgo, 2)),
    shortcut: '2yearsago',
    label: getYear(subYears(oneMonthAgo, 2))
  },
  threeYearsFromOMA: {
    start: format(startOfYear(subYears(oneMonthAgo, 3)), 'YYYY-MM-DD 00:00:00'),
    end: format(endOfYear(subYears(oneMonthAgo, 3)), 'YYYY-MM-DD 00:00:00'),
    year: getYear(subYears(oneMonthAgo, 3)),
    shortcut: '3yearsago',
    label: getYear(subYears(oneMonthAgo, 3))
  }
};

export const defaultDateRange = dateRanges.yearFromEndOfTMA;
