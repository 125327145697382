/* eslint-disable no-empty-pattern */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import { Vue2Storage } from 'vue2-storage';
import ability from '../../js/utilities/ability';

Vue.use(Vuex); // https://yarkovaleksei.github.io/vue2-storage/en/
// Localstorage
Vue.use(Vue2Storage, {
  prefix: 'CRCA_',
  driver: 'local',
  ttl: 0 // No lifetime, kept forever
});

const logoutMessages = {
  generic: 'You have been logged out.',
  timeout: 'You have been automatically logged out due to inactivity.'
};

const state = {
  token: Vue.$storage.get('token', ''),
  username: '',
  userHospitals: '',
  dataSegmentsFormatted: false,
  loginTimer: false,
  hasVarRepAccess: null,
  hasPopBuilderAccess: null,
  status: null,
  logoutReason: '',
  returnToPath: '',
  returnToData: {},
  freezeAuthSyncing: false,
  selectedHospitalList: [],
  hospitalSearchOption: []
};

// If you put it here, it will be cleared on logout/login
//    If you need it to stick between logins, don't add it here!
const clearedState = {
  token: '',
  username: '',
  userHospitals: '',
  dataSegmentsFormatted: false,
  loginTimer: false,
  hasVarRepAccess: null,
  hasPopBuilderAccess: null,
  status: null,
  freezeAuthSyncing: false
};

const getters = {
  getIsLoggedIn: (state) => !!state.token,
  getAuthStatus: (state) => state.status,
  getUsername: (state) => {
    if (state.username && state.username != '') {
      return state.username;
    } else {
      let username = Vue.$storage.get('username');
      state.username = username;
      return username;
    }
  },
  getCurrentHospital: (state) => {
    let hospital = null;
    if (Array.isArray(state.userHospitals) && state.userHospitals.length) {
      if (
        state.userHospitals[0] !== undefined &&
        Object.prototype.hasOwnProperty.call(state.userHospitals[0], 'name')
      ) {
        // we really shouldn't get this far if a user doesn't have a site, but we need requiremenst for how that senario should look.
        hospital = state.userHospitals[0].name;
      }
    }
    return hospital;
  },
  userHospitalList: (state) => {
    if (state.userHospitals) {
      return state.userHospitals;
    } else {
      let list = Vue.$storage.get('userHospitals');
      state.userHospitals = list;
      return list;
    }
  },
  userDataSegments: (state) => {
    if (!state.dataSegmentsFormatted) {
      let rawSegs = Vue.$storage.get('userDataSegments');
      let segsFormatted = [];

      for (let i in rawSegs) {
        segsFormatted.push({ text: rawSegs[i].name, value: rawSegs[i].id });
      }

      state.dataSegmentsFormatted = segsFormatted;
      return segsFormatted;
    } else {
      return state.dataSegmentsFormatted;
    }
  },
  getCanAccessAgilumAdmin: () => {
    return true;
  },
  getLogoutReason: (state) => {
    return state.logoutReason;
  },
  getReturnToPath: (state) => {
    return state.returnToPath;
  },
  getReturnToData: (state) => {
    return state.returnToData;
  },
  getFreezeAuthSyncing: (state) => {
    return state.freezeAuthSyncing;
  },
  getUserAccountId: () => {
    let accountId = Vue.$storage.get('userAccountId');
    return accountId;
  },
  getSelectedHospitalList: (state) => {
    if (state.selectedHospitalList) {
      return state.selectedHospitalList;
    } else {
      let list = Vue.$storage.get('selectedHospitalList');
      state.selectedHospitalList = list;
      return list;
    }
  },
  getHospitalSearchOption: (state) => {
    if (state.hospitalSearchOption) {
      return state.hospitalSearchOption;
    } else {
      let options = Vue.$storage.get('hospitalSearchOption');
      state.hospitalSearchOption = options;
      return options;
    }
  }
};

const mutations = {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_success(state, tokenHash) {
    const token = 'Bearer ' + tokenHash;

    Vue.$storage.set('token', token, { ttl: 31 * 60 * 1000 });
    Vue.$storage.set('idleTimeStart', Date.now());
    Vue.$storage.set('lastTokenRefreshTime', Date.now());

    axios.defaults.headers.common.Authorization = token;

    state.status = 'success';
    state.token = token;
  },
  auth_error(state) {
    state.status = 'error';
  },
  setUsername(state, username) {
    state.username = username;
    Vue.$storage.set('username', username);
  },
  updateUsernameFromStorage(state) {
    state.username = Vue.$storage.get('username');
  },
  clearUsername(state) {
    state.username = '';
    Vue.$storage.set('username', '');
  },
  startLoginTimer(state, t) {
    state.loginTimer = t;
  },
  logout(state) {
    console.log('TIMEOUT - YOU ARE BEING LOGGED OUT');
    state.status = '';
    state.token = '';
    Vue.$storage.clear();
    console.log('TIMEOUT - YOUR TOKEN: ' + state.token);
  },
  clear_store(state) {
    for (let i in clearedState) {
      state[i] = clearedState[i];
    }
  },
  user_hospitals(state, userHospitals) {
    state.userHospitals = userHospitals;
    Vue.$storage.set('userHospitals', userHospitals);
  },
  setLogoutReason(state, messageType) {
    if (logoutMessages[messageType]) {
      state.logoutReason = logoutMessages[messageType];
    }
  },
  clearLogoutReason(state) {
    state.logoutReason = '';
  },
  setReturnToPath(state, path) {
    state.returnToPath = path;
  },
  clearReturnToPath(state) {
    state.returnToPath = '';
  },
  setReturnToResponder(state, identifier) {
    state.returnToData[identifier] = {};
    state.returnToData[identifier].waiting = true;
  },
  setReturnToData(state, data) {
    state.returnToData[data.identifier].data = JSON.stringify(data.data);
  },
  clearReturnToData(state) {
    state.returnToData = {};
  },
  setReturnToDone(state, identifier) {
    state.returnToData[identifier].waiting = false;
  },
  setFreezeAuthSyncing(state, val) {
    state.freezeAuthSyncing = val;
  },
  setSelectedHospitalList: (state, hsptlList) => {
    Vue.$storage.set('selectedHospitalList', hsptlList);
    state.selectedHospitalList = hsptlList;
  },
  setHospitalSearchOption: (state, options) => {
    Vue.$storage.set('hospitalSearchOption', options);
    state.hospitalSearchOption = options;
  }
};

const actions = {
  redirectToAuthRequest() {
    const destinationUrl = new URL('/loginToken', window.location.origin);
    const loginUrl = new URL('/loginToken', process.env.VUE_APP_ROOT_API);

    loginUrl.search = new URLSearchParams({
      redirectURL: destinationUrl.toString()
    });

    window.location.replace(loginUrl);
  },
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request');

      commit('clear_store');
      Vue.$storage.clear();

      axios({
        url: '/login',
        data: user,
        method: 'POST',
      })
        .then((resp) => {
          commit('auth_success', resp.data.token);
          commit('setUsername', user.email);

          resolve(resp);
        })
        .catch(function (err) {
          commit('auth_error');
          reject(err);
        });
    });
  },
  getProfile({ commit, dispatch }, hospitalId) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/profile',
        method: 'GET',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json;charset=utf-8'
        }
      })
        .then((resp) => {
          // eslint-disable-next-line no-undef
          pendo.initialize({
            visitor: {
              id: resp.data.data.email, // Required if user is logged in
              email: resp.data.data.email, // Recommended if using Pendo Feedback, or NPS Email
              full_name:
                resp.data.data.firstName + ' ' + resp.data.data.lastName // Recommended if using Pendo Feedback
              // role:         // Optional

              // You can add any additional visitor level key-values here,
              // as long as it's not one of the above reserved names.
            },

            account: {
              id: resp.data.data.account.code, // Highly recommended
              name: resp.data.data.account.name // Optional
              // is_paying:    // Recommended if using Pendo Feedback
              // monthly_value:// Recommended if using Pendo Feedback
              // planLevel:    // Optional
              // planPrice:    // Optional
              // creationDate: // Optional

              // You can add any additional account level key-values here,
              // as long as it's not one of the above reserved names.
            }
          });
          // Account Id
          const accountId = resp.data.data.account.id;
          Vue.$storage.set('userAccountId', accountId);

          const userHospitals = resp.data.data.account.hospitals;

          if (userHospitals && userHospitals.length > 0) {
            if (
              hospitalId === '' ||
              hospitalId === null ||
              hospitalId === undefined
            ) {
              hospitalId = userHospitals[0].id;
            }

            commit('user_hospitals', userHospitals);
          } else {
            commit('user_hospitals', []);
          }
          // Data segments have to make it into local storage for page refreshes
          if (resp.data.data.account.dataSegments) {
            Vue.$storage.set(
              'userDataSegments',
              resp.data.data.account.dataSegments
            );
          } else {
            Vue.$storage.set('userDataSegments', []);
          }

          // Permisisons have to be stored in local storage to use when we change hospitals
          const permissionFlags = resp.data.data.permissions;
          Vue.$storage.set('userPermissions', permissionFlags);
          Vue.$storage.set(
            'eulaAccepted',
            resp.data.data.data &&
              resp.data.data.data.isAmaTermsAndConditionAccepted
          );
          dispatch('setPermissions');

          // External Reports
          if (resp.data.data.account.reports) {
            Vue.$storage.set(
              'external_reports',
              resp.data.data.account.reports
            );
          }

          resolve();
        })
        .catch((err) => {
          // no definition of how the UI should behave if we get this error.
          reject(err);
        });
    });
  },
  setPermissions({ commit }) {
    let allHospitalPermissions = Vue.$storage.get('userHospitals');
    let permissionFlags = Vue.$storage.get('userPermissions');

    let eulaAccepted = Vue.$storage.get('eulaAccepted');
    let updatedRules = [];

    if (eulaAccepted) {
      updatedRules.push({
        action: 'pass',
        subject: 'eula'
      });
    }

    let canAccessVarRep = false;
    let canAccessPopBuilder = false;

    // Allows us to go from frontend permission names to backend names later
    let permissionMap = {};

    // Clear the mapping
    Vue.$storage.remove('permissionMap');

    for (let i in permissionFlags) {
      // Variance reports
      if (i.indexOf('variance-report') != -1) {
        let permissionName = i.substr(16);
        let action = permissionName.substr(0, permissionName.indexOf('-'));
        let subject = permissionName.substr(action.length + 1);
        let subjectMod = '';

        // Hardcoding where we should not be hardcoding
        if (allHospitalPermissions.length != 0) {
          subjectMod = 'variance-report-' + subject;
        } else {
          subjectMod = 'variance-report-' + subject + '-index-only';
        }

        updatedRules.push({
          action: action,
          subject: subjectMod
        });

        permissionMap['variance-report-' + subject + ';' + action] = i;

        // If we're here, we have at least one variance report to access
        canAccessVarRep = true;
      } else if (i == 'tableau-access') {
        updatedRules.push({
          action: 'access',
          subject: 'externalreports'
        });

        permissionMap['externalreports;access'] = i;

        // If we're here, we have at least one variance report to access
        canAccessVarRep = true;
      } else if (i.indexOf('population-builder-rx') != -1) {
        let permissionName = i.substr(22);
        let action = permissionName.substr(0, permissionName.indexOf('-'));
        let subject = permissionName.substr(action.length + 1);

        updatedRules.push({
          action: action,
          subject: 'population-builder-rx-' + subject
        });

        permissionMap['population-builder-rx-' + subject + ';' + action] = i;

        // If we're here, we have at least one pop builder page to access
        canAccessPopBuilder = true;
      }

      // Product Area Permissions
      if (canAccessVarRep) {
        updatedRules.push({
          action: 'access',
          subject: 'variance-reports'
        });
      }

      if (canAccessPopBuilder) {
        updatedRules.push({
          action: 'access',
          subject: 'population-builder-rx'
        });
      }
    }

    Vue.$storage.set('casl-permissions', updatedRules);
    Vue.$storage.set('permissionMap', permissionMap);

    ability.update(updatedRules);
  },
  acceptEula() {
    return new Promise((resolve, reject) => {
      axios({
        url: '/accept-terms-conditions',
        method: 'PUT',
        data: { acceptedAma: true }
      })
        .then((resp) => {
          Vue.$storage.set('eulaAccepted', true);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resetEula() {
    return new Promise((resolve, reject) => {
      axios({
        url: '/accept-terms-conditions',
        method: 'PUT',
        data: { acceptedAma: false }
      })
        .then((resp) => {
          Vue.$storage.set('eulaAccepted', false);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  checkLoginStatus({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      if (Vue.$storage.get('token', '') != '') {
        // Throttle the requests
        if (Date.now() - Vue.$storage.get('lastTokenRefreshTime') >= 60000) {
          // One minute
          Vue.$storage.set('lastTokenRefreshTime', Date.now());

          axios({
            url: '/refresh-token',
            method: 'POST',
          })
            .then((resp) => {
              if (resp && resp.data && resp.data.status === true) {
                commit('auth_success', resp.data.token);
                resolve(true);
              } else {
                reject();
              }
            })
            .catch(function (err) {
              reject(err);
            });
        } else {
          resolve(true);
        }
      } else {
        resolve(false);
      }
    });
  },
  getHospitalsForPermission({ getters }, permission) {
    return new Promise((resolve, reject) => {
      let allHospitals = getters.userHospitalList;

      // If you didn't send a permission, just return the full list
      if (!permission || !permission.subject || !permission.action) {
        resolve(allHospitals);
      } else {
        let permissionMap = Vue.$storage.get('permissionMap');
        let permissionFlags = Vue.$storage.get('userPermissions');
        let originalPermissionName =
          permissionMap[permission.subject + ';' + permission.action];
        let originalPermission = permissionFlags[originalPermissionName];

        // The permission has hospital restrictions
        if (
          Array.isArray(originalPermission) &&
          originalPermission.length > 0
        ) {
          // Find the hospitals I have permission to use
          let allowedHospitals = [];

          for (let i in allHospitals) {
            if (originalPermission.includes(allHospitals[i].id)) {
              allowedHospitals.push(allHospitals[i]);
            }
          }

          resolve(allowedHospitals);
        }
        // The permission exists, but no hospital restrictions
        else if (originalPermission) {
          resolve(allHospitals);
        }
        // You sent me a permission you do not have at all
        else {
          resolve([]);
        }
      }
    });
  },
  logout({ state, dispatch, commit }, reason) {
    return new Promise((resolve) => {
      /* LOGOUT PROCESS
            - Emit the about-to-logout event
            - Wait a small amount of time to see if any process responds
            - If we find any responses...
              - Wait for all processes to tell us they're does
              - Use a maximum wait time in case something goes wrong
              - When ready, do logout
            - If no responses came in, do logout
      */

      // Have to freeze other syncing while we wait here - otherwise
      //    things will continue to run while we wait
      //    (could logout twice, for example)
      commit('setFreezeAuthSyncing', true);

      window.vm.$emit('logging-out', { reason: reason });

      let safetyTime = Date.now() + 6000; // 5 seconds, in case something goes wrong

      // Wait one second for other components to notify us that it's saving
      setTimeout(function () {
        let logoutWaiter = setInterval(function () {
          let needToWait = false;

          for (let i in state.returnToData) {
            if (state.returnToData[i].waiting) {
              needToWait = true;
            }
          }

          if (!needToWait || Date.now() >= safetyTime) {
            clearInterval(logoutWaiter);

            // Free up the syncs
            commit('setFreezeAuthSyncing', false);
            commit('logout');
            commit('clear_store');

            Vue.$storage.clear();
            delete axios.defaults.headers.common.Authorization;

            document.querySelector('.jqx-loader-modal')?.parentNode?.removeChild(overlay);

            if (reason == 'auto') {
              resolve();
            } else {
              const logoutUrl = new URL('/ssoLogout', process.env.VUE_APP_ROOT_API);
              window.location.replace(logoutUrl);
            }
          }
        }, 500);
      }, 1000);
    });
  },
  clearReturnToData({ state }, identifier) {
    return new Promise((resolve) => {
      state.returnToData[identifier] = {};
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
