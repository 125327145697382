import sumBy from 'lodash/sumBy';
import sum from 'lodash/sum';
import { filterArray } from '@/js/utilities';
import { genders, weightedAvgLos } from '@/data';

export const ageRanges = [
  { description: 'Unknown', code: '0' },
  { description: '0-17', code: '1' },
  { description: '18-24', code: '2' },
  { description: '25-34', code: '3' },
  { description: '35-44', code: '4' },
  { description: '45-54', code: '5' },
  { description: '55-64', code: '6' },
  { description: '65-74', code: '7' },
  { description: '75-84', code: '8' },
  { description: '85+', code: '9' }
];

export const calculateAgeRangeData = (
  population,
  options = { sumBy: 'count' }
) => {
  const data = {};
  // moved this default setting into the body of the function because
  // if null is passed in as the population argument it overrides the default array
  // this creates more needless checks to prevent js errors
  if (!population) {
    population = [];
  }
  ageRanges.forEach((range) => {
    data[range.description.toLowerCase()] = sumBy(
      filterArray(population, {
        ageRange: (ageRange) =>
          [range.code].includes(ageRange.toString()) ||
          [range.code].includes(ageRange)
      }),
      options.sumBy
    );
  });

  return data;
};

export const calculateAgeRangeByGenderData = (
  population,
  options = { sumBy: 'count' }
) => {
  const data = {};
  // moved this default setting into the body of the function because
  // if null is passed in as the population argument it overrides the default array
  // this creates more needless checks to prevent js errors
  if (!population) {
    population = [];
  }
  genders.forEach((g) => {
    const genderDesc = g.description.toLowerCase();
    data[genderDesc] = {};
    ageRanges.forEach((range) => {
      data[genderDesc][range.description.toLowerCase()] = sumBy(
        filterArray(population, {
          gender: (gender) =>
            [g.code].includes(gender.toString()) || [g.code].includes(gender),
          ageRange: (ageRange) =>
            [range.code].includes(ageRange.toString()) ||
            [range.code].includes(ageRange)
        }),
        options.sumBy
      );
    });
  });

  return data;
};

// visits only has readmission data
export const calculateAgeRangeReadmissions = (
  population,
  options = { sumBy: 'count' }
) => {
  const data = {};
  // moved this default setting into the body of the function because
  // if null is passed in as the population argument it overrides the default array
  // this creates more needless checks to prevent js errors
  if (!population) {
    population = [];
  }

  ageRanges.forEach((range) => {
    data[range.description.toLowerCase()] = sumBy(
      filterArray(population, {
        readmissionIndicator: (readmissionIndicator) => readmissionIndicator,
        ageRange: (ageRange) =>
          [range.code].includes(ageRange.toString()) ||
          [range.code].includes(ageRange)
      }),
      options.sumBy
    );
  });
  return data;
};

export const calculateAgeRangeLos = (
  population
) => {
  const data = {};
  // moved this default setting into the body of the function because
  // if null is passed in as the population argument it overrides the default array
  // this creates more needless checks to prevent js errors
  if (!population) {
    population = [];
  }

  ageRanges.forEach((range) => {
    data[range.description.toLowerCase()] = weightedAvgLos(
      filterArray(population, {
        ageRange: (ageRange) =>
          [range.code].includes(ageRange.toString()) ||
          [range.code].includes(ageRange)
      })
    );
  });
  return data;
};

// weighted average age
export const weightedAvgAge = (population = []) => {
  const weights = [];
  if (population.length > 0) {
    // extract avgAge times the count from each of the filtered population items
    population.forEach((item) => {
      if (item.avgAge && item.count) {
        weights.push(item.avgAge * item.count);
      }
    });
  } else {
    return null;
  }

  // divide the sum of the weights by the sum of the counts
  return sum(weights) / sumBy(population, 'count');
};
