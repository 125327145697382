import { reactive, computed } from '@vue/composition-api';
import cloneDeep from 'lodash/cloneDeep';

import { defaultDateRange } from '@/data';

import populationStore from '@/store_v2/populations/populations.js';

let populationId = 1;

let state = reactive({
  inUse: false,
  initialRules: [],
  localCriteria: [],
  filters: [],
  dataSegmentMode: 'hospital',
  dataSegment: false,
  comparisonDimensions: [],
  demographicFiltersVisits: {
    gender: function (gender) {
      let genderChoices = [];
      let key = findLocalCriteriaIndex('gender');

      if (state.localCriteria[key] && state.localCriteria[key].valueCodes) {
        for (let i in state.localCriteria[key].valueCodes) {
          genderChoices.push(state.localCriteria[key].valueCodes[i].code);
        }
      }

      return (
        genderChoices.includes(gender.toString().toLowerCase()) ||
        genderChoices.includes(gender)
      );
    },
    ageRange: function (ageRange) {
      let ageRangeChoices = [];
      let key = findLocalCriteriaIndex('ageRange');

      if (state.localCriteria[key] && state.localCriteria[key].valueCodes) {
        for (let i in state.localCriteria[key].valueCodes) {
          ageRangeChoices.push(state.localCriteria[key].valueCodes[i].code);
        }
      }

      return (
        ageRangeChoices.includes(ageRange.toString().toLowerCase()) ||
        ageRangeChoices.includes(ageRange)
      );
    }
  },
  demographicFiltersPatients: {
    gender: function (gender) {
      let genderChoices = [];
      let key = findLocalCriteriaIndex('gender');

      if (state.localCriteria[key] && state.localCriteria[key].valueCodes) {
        for (let i in state.localCriteria[key].valueCodes) {
          genderChoices.push(state.localCriteria[key].valueCodes[i].code);
        }
      }

      return (
        genderChoices.includes(gender.toString().toLowerCase()) ||
        genderChoices.includes(gender)
      );
    },
    ageRange: function (ageRange) {
      let ageRangeChoices = [];
      let key = findLocalCriteriaIndex('ageRange');

      if (state.localCriteria[key] && state.localCriteria[key].valueCodes) {
        for (let i in state.localCriteria[key].valueCodes) {
          ageRangeChoices.push(state.localCriteria[key].valueCodes[i].code);
        }
      }

      return (
        ageRangeChoices.includes(ageRange.toString().toLowerCase()) ||
        ageRangeChoices.includes(ageRange)
      );
    }
  }
});

let internalPopulationStore = null;

const linkToPopulationStore = function (s) {
  internalPopulationStore = s;
};

const populationStoreToUse = computed(() => {
  if (internalPopulationStore) {
    return internalPopulationStore;
  }

  return populationStore;
});

const setInUse = function (u) {
  state.inUse = u;
};
const isInUse = function () {
  return state.inUse;
};

const isWorkflowReady = function () {
  // Fill this out for real
  if (!state.localCriteria) return false;
  if (state.localCriteria.length == 0) return false;

  // Find the index for the date segment
  let dateIndex = -1;
  for (let i in state.localCriteria) {
    if (state.localCriteria[i].name == 'dateRange') {
      dateIndex = i;
      break;
    }
  }

  if (dateIndex == -1) return false;

  if (
    !state.localCriteria[dateIndex].valueDateRange ||
    !state.localCriteria[dateIndex].valueDateRange.start ||
    !state.localCriteria[dateIndex].valueDateRange.end
  ) {
    return false;
  }

  if (!state.dataSegment) return false;

  return true;
};

// Backup the initial rules for this population
const saveInitialRules = function (r) {
  state.initialRules = sanitizeFilters(cloneDeep(r));
};
const getInitialRules = function () {
  return state.initialRules;
};

const getCriteria = function () {
  return state.localCriteria;
};

const setCriteria = function (c) {
  state.localCriteria = c;
};

const getFilters = function (f) {
  return state.filters;
};

const setFilters = function (f) {
  state.filters = sanitizeFilters(f);
};

const sanitizeFilters = function (f) {
  for (let i = f.length - 1; i >= 0; i--) {
    if (
      f[i].name == 'dateRange' ||
      f[i].name == 'patientStatus' ||
      f[i].name == 'gender' ||
      f[i].name == 'ageRange'
    ) {
      f.splice(i, 1);
    }
  }

  return f;
};

const syncWithAnotherStore = function (other, type) {
  // type = null - only if population is not ready
  // type = force - overwrite always
  // type = global - only overwrite the global dims
  let shouldForce = type == 'force' || !isWorkflowReady();

  if (
    shouldForce ||
    (type == 'global' && isComparisonDimensionGlobal('date_range'))
  ) {
    setDateRange(other.getDateRange());
  }

  if (
    shouldForce ||
    (type == 'global' && isComparisonDimensionGlobal('patient_status'))
  ) {
    setPatientStatus(other.getPatientStatus());
  }

  if (
    shouldForce ||
    (type == 'global' && isComparisonDimensionGlobal('gender'))
  ) {
    setGender(other.getGender());
  }

  if (
    shouldForce ||
    (type == 'global' && isComparisonDimensionGlobal('age_range'))
  ) {
    setAgeRange(other.getAgeRange());
  }

  if (
    shouldForce ||
    (type == 'global' && isComparisonDimensionGlobal('data_segment'))
  ) {
    setDataSegment(other.getDataSegment());
  }
};

const resetPopulationCriteriaLocal = function (overwrite = false) {
  if (overwrite || state.localCriteria.length == 0) {
    state.localCriteria = cloneDeep(defaultCriteria);
  }
};

const resetPopulationFilters = function () {
  state.filters = [];
};

const getDataSegment = function () {
  return {
    segment: state.dataSegment,
    mode: state.dataSegmentMode
  };
};

const setDataSegment = function (v) {
  state.dataSegmentMode = v.mode;

  if (v.value) {
    state.dataSegment = v.value;
  } else if (v.segment) {
    state.dataSegment = v.segment;
  }
};

const isCurrentDataSegmentEqual = function (v) {
  return state.dataSegmentMode == v.mode && state.dataSegment == v.value;
};

const getComparisonDimensions = function () {
  return state.comparisonDimensions;
};

const isComparisonDimensionGlobal = function (d) {
  return !state.comparisonDimensions.includes(d);
};

const setComparisonDimensions = function (v) {
  state.comparisonDimensions = v;
};

// full - do you want the full structure, or just the values?
const getDateRange = function (full = false) {
  let i = findLocalCriteriaIndex('dateRange');

  if (i >= 0) {
    if (full) {
      return state.localCriteria[i];
    } else {
      return state.localCriteria[i].valueDateRange;
    }
  } else {
    return null;
  }
};

const setDateRange = function (value) {
  let i = findLocalCriteriaIndex('dateRange');

  if (i > -1) {
    state.localCriteria[i].valueDateRange = value;
    state.dirty = true;
  }
};

// full - do you want the full structure, or just the values?
const getPatientStatus = function (full = false) {
  return demographicFiltersFormatted('patientStatus', full);
};

const setPatientStatus = function (value) {
  let i = findLocalCriteriaIndex('patientStatus');

  if (i > -1) {
    state.localCriteria[i].valueCodes = patientStatusAPIFormatted(value);
    populationStoreToUse.value.setDirtyLocal(true);
  }
};

// full - do you want the full structure, or just the values?
const getGender = function (full = false) {
  return demographicFiltersFormatted('gender', full);
};

const setGender = function (value) {
  let i = findLocalCriteriaIndex('gender');

  if (i > -1) {
    state.localCriteria[i].valueCodes = genderCriteriaAPIFormatted(value);
    populationStoreToUse.value.setDirtyLocal(true);
  }
};

// full - do you want the full structure, or just the values?
const getAgeRange = function (full = false) {
  return demographicFiltersFormatted('ageRange', full);
};

const setAgeRange = function (value) {
  let i = findLocalCriteriaIndex('ageRange');

  if (i > -1) {
    state.localCriteria[i].valueCodes = ageRangeCriteriaAPIFormatted(value);
    populationStoreToUse.value.setDirtyLocal(true);
  }
};

// Private
const findLocalCriteriaIndex = function (name) {
  for (let i in state.localCriteria) {
    if (state.localCriteria[i].name == name) {
      return i;
    }
  }

  return -1;
};

const defaultCriteria = computed(() => {
  let c = [];

  c.push({
    name: 'dateRange',
    logicalOperator: 'none',
    operator: 'includes',
    valueDateRange: defaultDateRange
  });

  c.push({
    name: 'patientStatus',
    operator: 'includes',
    logicalOperator: 'or',
    valueCodes: [
      {
        code: 'inpatient'
      },
      {
        code: 'outpatient'
      }
    ]
  });

  c.push({
    name: 'gender',
    operator: 'includes',
    logicalOperator: 'or',
    valueCodes: [
      {
        code: '1'
      },
      {
        code: '2'
      }
    ]
  });

  c.push({
    name: 'ageRange',
    operator: 'includes',
    logicalOperator: 'or',
    valueCodes: [
      {
        code: '1'
      },
      {
        code: '2'
      },
      {
        code: '3'
      },
      {
        code: '4'
      },
      {
        code: '5'
      },
      {
        code: '6'
      },
      {
        code: '7'
      },
      {
        code: '8'
      },
      {
        code: '9'
      }
    ]
  });

  return c;
});

const demographicFiltersFormatted = function (key, full = false) {
  let i = findLocalCriteriaIndex(key);

  if (i >= 0) {
    if (full) {
      return state.localCriteria[i];
    } else {
      let f = [];

      for (let j in state.localCriteria[i].valueCodes) {
        f.push(state.localCriteria[i].valueCodes[j].code);
      }

      return f;
    }
  } else {
    return null;
  }
};

const patientStatusAPIFormatted = function (value) {
  let f = [];

  for (let i in value) {
    f.push({ code: value[i] });
  }

  return f;
};

const genderCriteriaAPIFormatted = function (value) {
  let f = [];

  for (let i in value) {
    f.push({ code: value[i] });
  }

  return f;
};

const ageRangeCriteriaAPIFormatted = function (value) {
  let f = [];

  for (let i in value) {
    f.push({ code: value[i] });
  }

  return f;
};

const whichStore = function () {
  console.log('Workflow ' + populationId);
};

const whichStoreString = function () {
  return 'Workflow ' + populationId;
};

const resetFiltersExcludingNdcs = function(){
  state.filters = state.filters.filter(flt => (flt.name=== 'drugGroups' || flt.name=== 'ndc'));
};

export default {
  demographicFiltersVisits: state.demographicFiltersVisits,
  demographicFiltersPatients: state.demographicFiltersPatients,
  linkToPopulationStore,
  setInUse,
  isInUse,
  isWorkflowReady,
  saveInitialRules,
  getInitialRules,
  getCriteria,
  setCriteria,
  getFilters,
  setFilters,
  getDataSegment,
  setDataSegment,
  isCurrentDataSegmentEqual,
  syncWithAnotherStore,
  resetPopulationCriteriaLocal,
  resetPopulationFilters,
  getComparisonDimensions,
  isComparisonDimensionGlobal,
  setComparisonDimensions,
  getDateRange,
  setDateRange,
  getPatientStatus,
  setPatientStatus,
  getGender,
  genderCriteriaAPIFormatted,
  setGender,
  getAgeRange,
  ageRangeCriteriaAPIFormatted,
  setAgeRange,
  whichStore,
  whichStoreString,
  resetFiltersExcludingNdcs
};
