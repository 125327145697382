export const hospitalList = () => {
  let options = [];
  return new Promise((resolve, reject) => {
    window.vm.$store
      .dispatch('auth/getHospitalsForPermission', {
        subject: window.vm.$route.meta.permission.subject,
        action: window.vm.$route.meta.permission.action
      })
      .then((hospitalList) => {
        if (hospitalList.length > 0) {
          for (let i in hospitalList) {
            options.push({
              text: hospitalList[i].name,
              value: hospitalList[i].id
            });
          }
        }
        resolve(options);
      })
      .catch((err) => {
        console.log(err);
        reject(options);
      });
  });
};
