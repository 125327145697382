const varianceMain = () =>
  import(
    /* webpackChunkName: "variance-reports" */ '../views/auth/variancereports/index'
  );
const varianceExternalReports = () =>
  import(
    /* webpackChunkName: "variance-reports-external" */ '../views/auth/variancereports/ExternalReports'
  );

const productTitle = 'Variance Reports';

export default [
  {
    path: '/variance-reports/summary',
    name: 'Summary',
    component: varianceMain,
    meta: {
      title: 'Summary',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'variancereports',
      navSection: 'Summary',
      apiEndpoint: 'summaryv2',
      productTitle: productTitle,
      permission: {
        action: 'view',
        subject: 'variance-report-summary'
      }
    }
  },
  {
    path: '/variance-reports/drug-pricing',
    name: 'DrugPricing',
    component: varianceMain,
    meta: {
      title: 'Drug Pricing',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'variancereports',
      navSection: 'DrugPricing',
      apiEndpoint: 'drugpricingv2',
      productTitle: productTitle,
      permission: {
        action: 'view',
        subject: 'variance-report-drug-pricing'
      }
    }
  },
  {
    path: '/variance-reports/drug-pricing-by-ndc',
    name: 'DrugPricingByNdc',
    component: () =>
      import(
        /* webpackChunkName: "DrugPricingByNdc" */ '../views/auth/variancereports/DrugPricingByNdc.vue'
      ),
    meta: {
      title: 'Drug Pricing by NDC',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'variancereports',
      navSection: 'DrugPricingByNdc',
      productTitle: productTitle,
      permission: {
        action: 'view',
        subject: 'variance-report-drug-pricing'
      }
    }
  },
  {
    path: '/variance-reports/cost-inpatient-per-day',
    name: 'CostInpatientPerDay',
    component: varianceMain,
    meta: {
      title: 'Cost Per Inpatient Day',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'variancereports',
      navSection: 'CostInpatientPerDay',
      apiEndpoint: 'drugcostipdayv2',
      productTitle: productTitle,
      permission: {
        action: 'view',
        subject: 'variance-report-drug-cost-in-patient-per-day'
      }
    }
  },
  {
    path: '/variance-reports/average-length-of-stay',
    name: 'AverageLengthOfStay',
    component: varianceMain,
    meta: {
      title: 'Average Length of Stay',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'variancereports',
      navSection: 'AverageLengthOfStay',
      apiEndpoint: 'alosv2',
      productTitle: productTitle,
      permission: {
        action: 'view',
        subject: 'variance-report-average-length-of-stay'
      }
    }
  },
  {
    path: '/variance-reports/readmissions',
    name: 'Readmissions',
    component: varianceMain,
    meta: {
      title: 'Readmissions',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'variancereports',
      navSection: 'Readmissions',
      apiEndpoint: 'readmissionv2',
      productTitle: productTitle,
      permission: {
        action: 'view',
        subject: 'variance-report-re-admissions'
      }
    }
  },
  {
    path: '/variance-reports/external-reports',
    name: 'Reports',
    component: varianceExternalReports,
    meta: {
      title: 'Reports',
      hideTitleHeader: true,
      requiresAuth: true,
      layout: 'MainApp',
      alternateContentLayout: 'externalreports',
      module: 'variancereports',
      navSection: 'Reports',
      productTitle: productTitle,
      permission: {
        action: 'access',
        subject: 'externalreports'
      }
    }
  },
  {
    path: '/variance-reports',
    name: 'Variance Reports',
    meta: {
      title: 'Variance Reports',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'variancereports',
      permission: productTitle
    }
  }
];
