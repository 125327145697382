import axios from 'axios';

const getDefaultState = () => {
  return {
    savedList: [],
    popData: {},
    loading: false,
    dirty: true
  };
};

const state = getDefaultState();

const getters = {
  getSavedList: (state) => state.savedList,
  getPopData: (state) => state.popData,
  getLoading: (state) => state.loading,
  getDirty: (state) => state.dirty
};

const mutations = {
  setSavedList(state, payload) {
    state.savedList = payload;
  },
  setPopData(state, payload) {
    state.popData = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setDirty(state, payload) {
    state.dirty = payload;
  }
};

const actions = {
  getSavedPopulationsList({ commit }) {
    commit('setLoading', true);
    return new Promise((resolve, reject) => {
      axios({
        url: '/filter-service/filters' // new URL
      })
        .catch((err) => {
          reject(err);
        })
        .then((resp) => {
          commit('setSavedList', resp.data.data);
          resolve();
        })
        .finally(() => {
          commit('setLoading', false);
        });
    });
  },

  //filter saved population by search string
  searchPopulationsList({ commit },payload) {
    commit('setLoading', true);
    let searchTerm=payload.searchString;
    let offset=payload.offset;
    let pageSize=payload.pageSize;
    return new Promise((resolve, reject) => {
      axios({
        url: '/filter-service/filters/search',
        params:{
          searchString:searchTerm,
          offset:offset,
          pageSize:pageSize
        } ,
        method: 'GET'
      })
        .catch((err) => {
          reject(err);
        })
        .then((resp) => {
          commit('setSavedList', resp.data.data);
          resolve();
        })
        .finally(() => {
          commit('setLoading', false);
        });
    });
  },

  deleteSavedPopulation({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/filter-service/filters/' + id, // new URL
        method: 'DELETE'
      })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          dispatch('getSavedPopulationsList');
          resolve();
        });
    });
  },
  savePopulation({ dispatch, rootGetters }, { data, id }) {
    return new Promise((resolve, reject) => {
      if (data.populationName === null) {
        reject('Please name your population.');
        return;
      }
      let url, method;
      if (id) {
        url = '/filter-service/filters/' + id;
        method = 'PUT';
      } else {
        url = '/filter-service/filters';
        method = 'POST';
      }
      axios({
        url: url,
        data: {
          populationName: data.populationName,
          rules: data.rules,
          logicalOperator: data.logicalOperator
        },
        method: method
      })
        .then((resp) => {
          dispatch('getSavedPopulationsList', id);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPopulationVisitCount({ commit }, { requestData, moduleNamespace }) {
    if (
      (!Object.prototype.hasOwnProperty.call(requestData, 'hospitalIds') &&
        !Object.prototype.hasOwnProperty.call(
          requestData,
          'comparisonIndex'
        )) ||
      (!requestData.hospitalIds && !requestData.comparisonIndex)
    ) {
      console.warn(
        'Either hospitalIds or comparisonIndex must be included in your request.'
      );
      return;
    }

    commit(moduleNamespace + '/setPopDataLoading', true, { root: true });
    return new Promise((resolve, reject) => {
      // Clear so that the first request loads in ok
      commit(moduleNamespace + '/setPopData', {}, { root: true });

      axios({
        url: '/data-service/visitCount/',
        params: requestData,
        // Need to do this for now, future improvement opportunity
        paramsSerializer(d) {
          let dataSegment = '';
          if (d.hospitalIds) {
            dataSegment = 'hospitalIds=' + d.hospitalIds;
          }
          if (d.comparisonIndex) {
            dataSegment = 'comparisonIndex=' + d.comparisonIndex;
          }
          return (
            dataSegment + '&filterRules=' + encodeURIComponent(d.filterRules)
          );
        }
      })
        .then((res) => {
          commit(moduleNamespace + '/setPopData', res.data, { root: true });

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit(moduleNamespace + '/setPopDataLoading', false, { root: true });
        });
    });
  },

  getPopulationPatientCount({ commit }, { requestData, moduleNamespace }) {
    if (
      (!Object.prototype.hasOwnProperty.call(requestData, 'hospitalIds') &&
        !Object.prototype.hasOwnProperty.call(
          requestData,
          'comparisonIndex'
        )) ||
      (!requestData.hospitalIds && !requestData.comparisonIndex)
    ) {
      console.warn(
        'Either hospitalIds or comparisonIndex must be included in your request.'
      );
      return;
    }

    commit(moduleNamespace + '/setPopDataLoading', true, { root: true });
    return new Promise((resolve, reject) => {
      // Clear so that the first request loads in ok
      commit(moduleNamespace + '/setPopData', {}, { root: true });

      axios({
        url: '/data-service/patientCount/',
        params: requestData,
        // Need to do this for now, future improvement opportunity
        paramsSerializer(d) {
          let dataSegment = '';
          if (d.hospitalIds) {
            dataSegment = 'hospitalIds=' + d.hospitalIds;
          }
          if (d.comparisonIndex) {
            dataSegment = 'comparisonIndex=' + d.comparisonIndex;
          }
          return (
            dataSegment + '&filterRules=' + encodeURIComponent(d.filterRules)
          );
        }
      })
        .then((res) => {
          commit(moduleNamespace + '/setPopData', res.data, { root: true });

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit(moduleNamespace + '/setPopDataLoading', false, { root: true });
        });
    });
  },
  // Keeping for now, just in case. But keep an eye on this code as it may be obsolete.
  getPopulationMetric({ commit }, { requestData, moduleNamespace }) {
    if (
      (!Object.prototype.hasOwnProperty.call(requestData, 'hospitalIds') &&
        !Object.prototype.hasOwnProperty.call(
          requestData,
          'comparisonIndex'
        )) ||
      (!requestData.hospitalIds && !requestData.comparisonIndex)
    ) {
      console.warn(
        'Either hospitalIds or comparisonIndex must be included in your request.'
      );
      return;
    }
    commit(moduleNamespace + '/setPopDataLoading', true, { root: true });
    return new Promise((resolve, reject) => {
      // Clear so that the first request loads in ok
      commit(moduleNamespace + '/setPopData', {}, { root: true });

      axios({
        url: '/data-service/populationStatsByMetric/',
        params: requestData,
        // Need to do this for now, future improvement opportunity
        paramsSerializer(d) {
          let dataSegment = '';
          if (d.hospitalIds) {
            dataSegment = 'hospitalIds=' + d.hospitalIds;
          }
          if (d.comparisonIndex) {
            dataSegment = 'comparisonIndex=' + d.comparisonIndex;
          }
          return (
            dataSegment +
            '&byMetric=' +
            d.byMetric +
            '&filterRules=' +
            encodeURI(d.filterRules)
          );
        }
      })
        .then((res) => {
          commit(moduleNamespace + '/setPopData', res.data, { root: true });
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit(moduleNamespace + '/setPopDataLoading', false, { root: true });
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
