import { reactive, readonly, computed } from '@vue/composition-api';
import Axios from 'axios';

import workflowStoreTwo from '@/store_v2/populations/population_workflow.js';

let populationId = 2;

let state = reactive({
  inUse: false,
  dirty: false, // The population needs to be re-requested
  dirtyLocal: false, // The population needs to be re-filtered (frontend)
  isSaved: true,
  name: '',
  savedId: null,
  slug: null,
  workingVisit: false,
  visitData: [],
  workingPatient: false,
  patientData: [],
  populationIsReady: false,
  previousRequestRules: {},
  currentPopulationSelection: {}
});

let internalWorkflowStore = null;
let allowDuplicates = false;

const reset = function () {
  state.dirty = false;
  state.dirtyLocal = false;
  state.isSaved = true;
  state.name = '';
  state.savedId = null;
  state.slug = null;
  state.workingVisit = false;
  state.visitData = [];
  state.workingPatient = false;
  state.patientData = [];
};

const resetPreviousRequest = function () {
  state.previousRequestRules = {};
};
const linkToWorkflowStore = function (s) {
  internalWorkflowStore = s;
};
const workflowStoreToUse = function () {
  if (internalWorkflowStore) {
    return internalWorkflowStore;
  }

  return workflowStoreTwo;
};
const setInUse = function (u) {
  state.inUse = u;
};
const isInUse = function () {
  return state.inUse;
};
const isDirty = function () {
  return state.dirty;
};
const setDirty = function (v) {
  state.dirty = v;
};

const isDirtyLocal = function () {
  return state.dirtyLocal;
};
const setDirtyLocal = function (v) {
  state.dirtyLocal = v;
};

const isSaved = function () {
  return state.isSaved;
};

// Force a particular saved state
const setSaved = function (s) {
  state.isSaved = s;
};

// Calculate automatically (use this usually)
const recalculateSavedFlag = function () {
  setSaved(
    areFilteredEqual(
      workflowStoreToUse().getFilters(),
      workflowStoreToUse().getInitialRules()
    )
  );
};

const getName = function () {
  return state.name;
};

const getCurrentPopulationSelection = function () {
  return state.currentPopulationSelection;
};

const setCurrentPopulationSelection = function (v) {
  state.currentPopulationSelection = v;
};

const getSavedId = function () {
  return state.savedId;
};

const getSavedPopulationById = async function (id) {
  await Axios({
    url: '/filter-service/filters/' + id
  }).then((res) => {
    loadSavedPopulationByFilterData(res.data.data);
  });
};

const loadSavedPopulationByFilterData = function (data) {
  if (data.id) {
    state.name = data.filterData.populationName;
    state.savedId = data.id;
    state.slug = data.name;

    workflowStoreToUse().saveInitialRules(data.filterData.rules);
    workflowStoreToUse().setFilters(data.filterData.rules);
    recalculateSavedFlag();

    state.populationIsReady = true;
    loadPopulation();
  }
};

const areFilteredEqual = function (a, b) {
  if (a.length == 0 && b.length == 0) return true;

  for (let i in a) {
    if (!b[i]) return false;

    for (let ai in a[i]) {
      if (ai == 'localKey') continue;

      if (b[i][ai] && JSON.stringify(a[i][ai]) == JSON.stringify(b[i][ai])) {
        continue;
      }

      return false;
    }
  }
  return true;
};

const isPopulationSaved = function () {
  return areFilteredEqual(
    workflowStoreToUse().getFilters(),
    workflowStoreToUse().getInitialRules()
  );
};

const setDuplicateRequestAllowed = function (d) {
  allowDuplicates = d;
};

const loadPopulation = function () {
  state.dirty = false;
  state.dirtyLocal = false;

  loadVisitData();
  loadPatientData();
};

const getRequestData = function () {
  /*
    Criteria = Demographics/date/patient status
    Filters = Characteristics
  */

  // Grab the criteria we need
  let criteria = [];
  criteria.push(workflowStoreToUse().getDateRange(true));

  let fullData = {
    logicalOperator: 'and',
    rules: workflowStoreToUse().getFilters().concat(criteria)
  };

  const requestData = {
    filterRules: JSON.stringify(fullData)
  };

  let dataSegment = workflowStoreToUse().getDataSegment();

  if (dataSegment.mode == 'hospital') {
    requestData.hospitalIds =
      dataSegment.segment !== undefined &&
      dataSegment.segment !== null &&
      dataSegment.segment.length > 0
        ? dataSegment.segment.map((hsp) => hsp.value)
        : [];
  } else if (dataSegment.mode == 'rwd') {
    requestData.comparisonIndex = dataSegment.segment.value;
  }

  return requestData;
};

const loadVisitData = function () {
  if (populationIsSound()) {
    let requestData = getRequestData();
    if (!validateRequest(requestData)) {
      return;
    }
    state.workingVisit = true;

    if (allowDuplicates || !requestIsDuplicate(requestData, 'visit')) {
      state.previousRequestRules.visit = requestData;
      // skiping API call if hospitalIds / comparisonIndex not exist
      const paramData = new URLSearchParams(requestData);
      if (
        (paramData.get('hospitalIds') !== undefined &&
          paramData.get('hospitalIds') !== null &&
          paramData.get('hospitalIds').length > 0) ||
        (paramData.get('comparisonIndex') !== undefined &&
          paramData.get('comparisonIndex') !== null &&
          paramData.get('comparisonIndex').length > 0)
      ) {
        Axios({
          url: '/data-service/visitCount/',
          params: requestData,
          // Need to do this for now, future improvement opportunity
          paramsSerializer(d) {
            let dataSegment = '';
            if (d.hospitalIds) {
              dataSegment = 'hospitalIds=' + d.hospitalIds;
            } else if (d.comparisonIndex) {
              dataSegment = 'comparisonIndex=' + d.comparisonIndex;
            } else {
              console.log('Data request must include hospital ID or index ID');
            }

            return (
              dataSegment + '&filterRules=' + encodeURIComponent(d.filterRules)
            );
          }
        })
          .then((res) => {
            state.visitData = res.data;
          })
          .finally(() => {
            state.workingVisit = false;
          });
      } else {
        state.workingVisit = false;
      }
    } else {
      state.workingVisit = false;
    }
  }
};

const loadPatientData = function () {
  if (populationIsSound()) {
    let requestData = getRequestData();
    if (!validateRequest(requestData)) {
      return;
    }
    state.workingPatient = true;

    if (allowDuplicates || !requestIsDuplicate(requestData, 'patient')) {
      state.previousRequestRules.patient = requestData;

      // skiping API call if hospitalIds / comparisonIndex not exist
      const paramData = new URLSearchParams(requestData);
      if (
        (paramData.get('hospitalIds') !== undefined &&
          paramData.get('hospitalIds') !== null &&
          paramData.get('hospitalIds').length > 0) ||
        (paramData.get('comparisonIndex') !== undefined &&
          paramData.get('comparisonIndex') !== null &&
          paramData.get('comparisonIndex').length > 0)
      ) {
        Axios({
          url: '/data-service/patientCount/',
          params: requestData,
          // Need to do this for now, future improvement opportunity
          paramsSerializer(d) {
            let dataSegment = '';
            if (d.hospitalIds) {
              dataSegment = 'hospitalIds=' + d.hospitalIds;
            } else if (d.comparisonIndex) {
              dataSegment = 'comparisonIndex=' + d.comparisonIndex;
            } else {
              console.log('Data request must include hospital ID or index ID');
            }

            return (
              dataSegment + '&filterRules=' + encodeURIComponent(d.filterRules)
            );
          }
        })
          .then((res) => {
            state.patientData = res.data;
          })
          .finally(() => {
            state.workingPatient = false;
          });
      } else {
        state.workingPatient = false;
      }
    } else {
      state.workingPatient = false;
    }
  }
};

const getVisitData = function () {
  return state.visitData;
};

const getPatientData = function () {
  return state.patientData;
};

const visitsLoading = function () {
  return state.workingVisit;
};

const patientsLoading = function () {
  return state.workingPatient;
};

const isWorking = computed(() => state.workingVisit || state.workingPatient);

const syncWithAnotherStore = function (other) {
  setCurrentPopulationSelection(other.getCurrentPopulationSelection());
};

const LoadDefaultCriteria = function (overwrite = false) {
  // overwrite = true will ALWAYS reset the criteria with the default
  // overwrite = false will set to default if nothing is set currently
  //    (allows it to remember settings if navigating back)
  workflowStoreToUse().resetPopulationCriteriaLocal(overwrite);
};

const loadDefaultFilters = function () {
  workflowStoreToUse().resetPopulationFilters();
};

const populationIsSound = function () {
  let workflowReady = workflowStoreToUse().isWorkflowReady();

  if (workflowReady && state.populationIsReady) {
    return true;
  } else {
    return false;
  }
};

const requestIsDuplicate = function (newRequest, type) {
  return (
    JSON.stringify(newRequest) ==
    JSON.stringify(state.previousRequestRules[type])
  );
};

const setPopulationReady = function () {
  state.populationIsReady = true;
};

const validateRequest = function (requestData) {
  if (
    (!Object.prototype.hasOwnProperty.call(requestData, 'hospitalIds') &&
      !Object.prototype.hasOwnProperty.call(requestData, 'comparisonIndex')) ||
    (!requestData.hospitalIds && !requestData.comparisonIndex)
  ) {
    console.warn(
      'Either hospitalIds or comparisonIndex must be included in your request.'
    );
    return false;
  }

  return true;
};

const whichStore = function () {
  console.log('Population ' + populationId);
};

const whichStoreString = function () {
  return 'Population ' + populationId;
};

export default {
  data: readonly(state.visitData),
  reset,
  resetPreviousRequest,
  linkToWorkflowStore,
  setInUse,
  isInUse,
  isDirty,
  setDirty,
  isDirtyLocal,
  setDirtyLocal,
  isSaved,
  setSaved,
  recalculateSavedFlag,
  getName,
  getCurrentPopulationSelection,
  setCurrentPopulationSelection,
  getSavedId,
  setDuplicateRequestAllowed,
  getSavedPopulationById,
  loadSavedPopulationByFilterData,
  isPopulationSaved,
  loadPopulation,
  loadVisitData,
  loadPatientData,
  getVisitData,
  getPatientData,
  visitsLoading,
  patientsLoading,
  isWorking,
  syncWithAnotherStore,
  LoadDefaultCriteria,
  loadDefaultFilters,
  setPopulationReady,
  populationIsSound,
  whichStore,
  whichStoreString
};
