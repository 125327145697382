import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth.js';
import populationManagement from './modules/popbuilder/population-management.js';
import medicationGroupsManagement from './modules/popbuilder/medication-groups-management.js';
import ndcOpportunity from './modules/variancereports/ndcOpportunity.js';
import diagnosisGroupsManagement from './modules/popbuilder/diagnosis-groups-management.js';

import medicationCardGroupsManagement from './modules/popbuilder/medicationCardGroupsManagement.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    populationManagement,
    medicationGroupsManagement,
    ndcOpportunity,
    medicationCardGroupsManagement,
    diagnosisGroupsManagement
  }
});
