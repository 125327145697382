import axios from 'axios';

const state = {
	diagnosisList: "",
	diagnosisGroup: undefined,
	loading: false
}

const DIAGNOSIS_GROUP_SEARCH_URL = '/data-service/diagnosisGroups';
const DIAGNOSIS_GROUP_URL = '/filter-service/diagnosisGroup';

const getters = {
	getDiagnosisList: (state) => state.diagnosisList,
	getDiagnosisGroup: (state) => state.diagnosisGroup,
	getLoading: (state) => state.loading
}

const mutations = {
	setDiagnosisList(state, payload) {
		state.diagnosisList = payload;
	},
	setDiagnosisGroup(state, payload) {
		state.diagnosisGroup = payload;
	},
	setLoading(state, payload) {
		state.loading = payload
	}
}

const actions = {
	getDiagnosisGroupsList({ commit }, obj, id) {
		commit('setLoading', true);
		let url = DIAGNOSIS_GROUP_SEARCH_URL;
		return new Promise((resolve, reject) => {
			axios.get(url, {
				params: {
					searchString: (obj.searchString !== undefined && obj.searchString.trim() !== '') ? obj.searchString.trim() : '',
					offset: obj.offset,
					limit: obj.pageSize
				}
			})
			.catch(err => reject(err))
			.then(res => {
				commit('setDiagnosisList', res.data);
				resolve();
			})
			.finally(() => {
				commit('setLoading', false);
			})
		});
	},
	getDiagnosisGroup({ commit }, id) {
		commit('setLoading', true);
		let url = `${DIAGNOSIS_GROUP_URL}/${id}`;
		return new Promise((resolve, reject) => {
			axios.get(url)
			.then(res => {
				commit('setDiagnosisGroup', res.data);
				resolve(res.data);
			})
			.catch(err => {
				reject(err)
			})
			.finally(() => {
				commit('setLoading', false);
			})
		});
	},
	getAllDiagnosisGroupsList({ commit }) {
		commit('setLoading', true);
		let url = DIAGNOSIS_GROUP_URL;
		return new Promise((resolve, reject) => {
			axios.get(url)
			.catch(err => reject(err))
			.then(res => {
				commit('setDiagnosisList', res.data);
				resolve();
			})
			.finally(() => {
				commit('setLoading', false);
			})
		});
	},
	createOrUpdateDiagnosisGroup({ commit, dispatch }, dataObj) {
		commit('setLoading', true);
		let url = DIAGNOSIS_GROUP_URL;
		let methodType = "POST";
		if (dataObj.id !== undefined && dataObj.id !== null && dataObj.id > 0) {
			url += `/${dataObj.id}`;
			methodType = "PUT"
		}
		return new Promise((resolve, reject) => {
			// saving/updating diagnosis
			axios(url, {
				method: methodType,
				data: dataObj
			})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.name)
			})
			.finally(() => {
				commit('setLoading', false);
			})
		});
	},
	deleteDiagnosisGroup({ commit, dispatch }, id) {
		commit('setLoading', true);
		let url = `${DIAGNOSIS_GROUP_URL}/${id}`;
		return new Promise((resolve, reject) => {
			axios.delete(url)
				.catch(err => reject(err))
				.then(res => {
					resolve(res.data);
				})
				.finally(() => {
					commit('setLoading', false);
				})
		});
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
