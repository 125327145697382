<template>
  <div id="app" class="crca-app" :class="[dynamicClasses]">
    <component :key="layout" v-if="layout" :is="layout"></component>
    <ModalIdle
      v-if="showIdleMessage"
      :secondsIdle="secondsIdle"
      :timeoutSeconds="timeoutSeconds"
      style="z-index: 2000"
    />
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
  import kebabCase from 'lodash/kebabCase';
  import store from '@/store_v2';
  import Snotify from 'vue-snotify';

  // Feature stores
  import popBuilderCompareStore from '@/store_v2/features/PopBuilderRxTMCompare.js';

  // Population stores
  import populationStore from '@/store_v2/populations/populations.js';
  import populationStore2 from '@/store_v2/populations/populations2.js';
  import populationStore3 from '@/store_v2/populations/populations3.js';
  import populationStore4 from '@/store_v2/populations/populations4.js';
  import populationWorkflowStore from '@/store_v2/populations/population_workflow.js';
  import populationWorkflowStore2 from '@/store_v2/populations/population_workflow2.js';
  import populationWorkflowStore3 from '@/store_v2/populations/population_workflow3.js';
  import populationWorkflowStore4 from '@/store_v2/populations/population_workflow4.js';

  import { provide } from '@vue/composition-api';

  const Landing = () => import('../src/views/layout/Landing');
  const MainApp = () => import('../src/views/layout/MainApp');
  const ModalIdle = () => import('@/components/ModalIdle');

  export default {
    name: 'app',
    setup() {
      provide('store', store);
      provide('snotify', Snotify);
      provide('popBuilderCompareStore', popBuilderCompareStore);
      provide('populationStore', populationStore);
      provide('populationStore2', populationStore2);
      provide('populationStore3', populationStore3);
      provide('populationStore4', populationStore4);
      provide('populationWorkflowStore', populationWorkflowStore);
      provide('populationWorkflowStore2', populationWorkflowStore2);
      provide('populationWorkflowStore3', populationWorkflowStore3);
      provide('populationWorkflowStore4', populationWorkflowStore4);
    },
    components: {
      Landing,
      MainApp,
      ModalIdle
    },
    data() {
      return {
        isWindowActive: true,
        lastTickIdle: false,
        timeoutSeconds: process.env.VUE_APP_LOGIN_TIMEOUT_MIN * 60,
        secondsWhenIdleMessageShows: 60 * 5, // 5 minutes
        secondsIdle: 0,
        showIdleMessage: false,
        syncTimer: false
      };
    },
    computed: {
      layout() {
        var layout = this.$route.meta.layout;
        if (layout) {
          return layout;
        }
        return null;
      },
      isLoggedIn() {
        return this.$store.getters['auth/getIsLoggedIn'];
      },
      isWindowInCommand() {
        return this.isWindowActive;
      },
      isIdle() {
        return this.$store.state.idleVue.isIdle;
      },
      dynamicClasses() {
        const routerClasses =
          kebabCase(this.layout) +
          ' ' +
          kebabCase(this.$route.meta.module) +
          ' ' +
          kebabCase(this.$route.name);
        return routerClasses;
      }
    },
    methods: {
      onTabChange() {
        this.isWindowActive = document.visibilityState == 'visible';

        if (this.$route.meta.requiresAuth && this.isWindowActive) {
          // Immediately check for login state if we should be logged in
          this.checkLogin();
        }
      },
      onTabFocus() {
        if (this.$route.meta.requiresAuth) {
          // Immediately check for login state if we should be logged in
          this.checkLogin();
        }
      },
      checkLogin() {
        if (this.$route.meta.requiresAuth) {
          this.$store.dispatch('auth/checkLoginStatus').then((isLoggedIn) => {
            if (!isLoggedIn) {
              this.$store.dispatch('auth/logout', 'auto').then(() => {
                this.$store.commit('auth/setLogoutReason', 'timeout');
                this.$store.commit('auth/setReturnToPath', this.$route.path);
                this.$router.push({ name: 'Login' });
              });
            }
          });
        }
      }
    },
    mounted() {
      // Remove first to make sure we don't add twice
      document.removeEventListener('visibilitychange', this.onTabChange);
      document.addEventListener('visibilitychange', this.onTabChange);
      window.removeEventListener('focus', this.onTabFocus);
      window.addEventListener('focus', this.onTabFocus);

      // Global timer for syncs
      // eslint-disable-next-line no-unused-vars
      this.syncTimer = setInterval(() => {
        // If syncing is frozen, then skip the timer
        if (!this.$store.getters['auth/getFreezeAuthSyncing']) {
          // Save this because it's possible for the route to change mid-timer
          let currentRoute = this.$route;

          // Idle timeout
          if (this.isLoggedIn) {
            let now = Date.now();

            // Server token refresh
            if (this.$storage.has('lastTokenRefreshTime')) {
              let lastTokenRefreshTime = this.$storage.get(
                'lastTokenRefreshTime'
              );
              if (now - lastTokenRefreshTime >= 1440000) {
                // 25 minutes
                this.$store.dispatch('auth/checkLoginStatus');
              } else {
              }
            } else {
              this.$storage.set('lastTokenRefreshTime', now);
            }

            // If last tick we were active, and this tick we are idle, set the localstorage state
            if (this.isIdle && !this.lastTickIdle) {
              this.$storage.set('activeState', false);
              this.$storage.set('idleTimeStart', now);
              this.lastTickIdle = true;
            }
            // If last tick we were idle, and this tick we are active, set the localstorage state
            else if (!this.isIdle && this.lastTickIdle) {
              this.$storage.set('activeState', true);
              this.lastTickIdle = false;
              this.showIdleMessage = false;
            }

            if (this.$storage.get('activeState')) {
              this.showIdleMessage = false;
            } else {
              this.secondsIdle =
                (now - this.$storage.get('idleTimeStart')) / 1000;
              let idleSecondsLeft = this.timeoutSeconds - this.secondsIdle;

              if (idleSecondsLeft <= this.secondsWhenIdleMessageShows) {
                this.showIdleMessage = true;

                if (idleSecondsLeft <= 0) {
                  // Have to store this before logging out or we lost the storage
                  this.$store.commit('auth/setReturnToPath', currentRoute.path);

                  // Save username in store in case it's been lost
                  this.$store.commit('auth/updateUsernameFromStorage');

                  this.$store.commit('auth/setLogoutReason', 'timeout');
                  this.$store.dispatch('auth/logout', 'idle').then(() => {
                    this.$router.push({ name: 'Login' });
                  });
                }
              }
            }
          } else {
            this.showIdleMessage = false;
          }
        }
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.syncTimer);
    }
  };
</script>

<style lang="scss">
  @use '../node_modules/vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
  @use '../src/assets/scss/crca_styles.scss';
</style>
