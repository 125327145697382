import sumBy from 'lodash/sumBy';
import { filterArray } from '@/js/utilities';
import { calculateReadmissionCounts } from '@/data';

export const patientStatus = [
  {
    description: 'Inpatient',
    code: 'inpatient'
  },
  {
    description: 'Outpatient',
    code: 'outpatient'
  }
];
// TODO - Can remove with removal of old version of pop builder
export const calculatePatientStatusData = (population = []) => {
  const data = {};
  if (population) {
    patientStatus.forEach((type) => {
      data[type.code] = sumBy(
        filterArray(population, {
          patientStatus: (patientStatus) =>
            [type.code].includes(patientStatus.toLowerCase())
        }),
        'count'
      );
    });
  }

  return data;
};
// TODO - Can remove with removal of old version of pop builder
export const calculatePatientStatusWithReadmissionData = (population = []) => {
  const data = {};
  if (population) {
    patientStatus.forEach((type) => {
      let filteredData = filterArray(population, {
        patientStatus: (patientStatus) =>
          [type.code].includes(patientStatus.toLowerCase())
      });
      data[type.code] = {
        totalCount: sumBy(filteredData, 'count'),
        readmissionCount: calculateReadmissionCounts(filteredData)
      };
    });
  }

  return data;
};

// duplicating this for now to not break old version, but to add clarity that this is only for Visits
export const calculatePatientStatusWithReadmissionDataVisits = (
  population = []
) => {
  const data = {};
  if (population) {
    patientStatus.forEach((type) => {
      let filteredData = filterArray(population, {
        patientStatus: (patientStatus) =>
          [type.code].includes(patientStatus.toLowerCase())
      });
      data[type.code] = {
        totalCount: sumBy(filteredData, 'count'),
        readmissionCount: calculateReadmissionCounts(filteredData)
      };
    });
  }

  return data;
};
// duplicating this for now to not break old version, but to add clarity that this is only for Visits
export const calculatePatientStatusDataVisits = (population = []) => {
  const data = {};
  if (population) {
    patientStatus.forEach((type) => {
      data[type.code] = sumBy(
        filterArray(population, {
          patientStatus: (patientStatus) =>
            [type.code].includes(patientStatus.toLowerCase())
        }),
        'count'
      );
    });
  }

  return data;
};

// Patients data has no readmissions indicator so we can only get the counts by status
export const calculatePatientStatusDataPatients = (population = []) => {
  const data = {};
  if (population) {
    data.inpatient = sumBy(population, 'inpatientCount');
    data.outpatient = sumBy(population, 'outpatientCount');
  }

  return data;
};
