import axios from 'axios';

const getDefaultState = () => {
  return {
    drugGroupIds: [],
    loadingDrugGroupIds: false,
    drugGroupDetails: [],
    loadingDrugGroupDetails: false,
    drugGroupDirty: false,
  };
};
const state = getDefaultState();

const getters = {
  getIsDirty: (state) => state.drugGroupDirty,
  getDrugGroupIds: (state) => state.drugGroupIds,
};

const mutations = {
  setDrugGroupIds(state, payload) {
    state.drugGroupIds = payload;
  },
  setLoadingDrugGroupIds(state, payload) {
    state.loadingDrugGroupIds = payload;
  },
  setDrugGroupDetails(state, payload) {
    state.drugGroupDetails = payload;
  },
  setLoadingDrugGroupDetails(state, payload) {
    state.loadingDrugGroupDetails = payload;
  },
  setDrugGroupsDirty(state, payload) {
    state.drugGroupDirty = payload;
  },
  clearOnLogout(state, payload) {
    state = getDefaultState();
  }
};
const actions = {
  requestDrugGroupIds({ commit }, { requestData }) {
    commit('setLoadingDrugGroupIds', true);
    return new Promise((resolve, reject) => {
      axios({
        url: '/data-service/drugGroupingIds',
        params: requestData
      })
        .then((resp) => {
          // commits need to happen template side because we may need to overwrite store object or add to it
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoadingDrugGroupIds', false);
        });
    });
  },
  requestDrugGroupDetails({ commit }, { requestData }) {
    commit('setLoadingDrugGroupDetails', true);

    return new Promise((resolve, reject) => {
      axios({
        url: '/data-service/drugGrouping/detailList/',
        params: requestData
      })
        .then((resp) => {
          commit('setDrugGroupsDirty', false);

          // commits need to happen template side because we may need to overwrite store object or add to it
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoadingDrugGroupDetails', false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
