// Base Vue things
import './installCompositionApi.js';
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Axios from 'axios';

// Core 3rd party node modules
import breakpoints from '../node_modules/include-media-export/dist/include-media-1.0.2.min.js'; // https://github.com/eduardoboucas/include-media-export
import { library } from '@fortawesome/fontawesome-svg-core'; // https://fontawesome.com/how-to-use/on-the-web/using-with/vuejs
import {
  faCheck,
  faHamburger,
  faArrowDown,
  faArrowUp,
  faSignInAlt,
  faEdit,
  faTrash,
  faPlusSquare,
  faQuestionCircle,
  faExclamationTriangle,
  faCalendarAlt,
  faFilter,
  faAngleDown,
  faSearch,
  faExclamationCircle,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome/index';
import IdleVue from 'idle-vue'; // https://www.npmjs.com/package/idle-vue https://medium.com/js-dojo/how-to-set-timer-idle-in-vue-1f4b57beb886
import VModal from 'vue-js-modal'; // https://euvl.github.io/vue-js-modal/
import crcaColors from './assets/scss/colors_vars.scss';
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'; // https://github.com/Akryum/v-tooltip#usage
import Snotify, { SnotifyPosition } from 'vue-snotify'; // https://artemsky.github.io/vue-snotify/documentation/
import { abilitiesPlugin } from '@casl/vue'; // https://www.npmjs.com/package/@casl/vue
import PortalVue from 'portal-vue'; // https://portal-vue.linusb.org/guide/getting-started.html
import ability from './js/utilities/ability';
import { VueResponsiveComponents } from 'vue-responsive-components';

// CRCA stuff
import loader from './components/loaders/loader';
import filters from '@/js/filters';
import selectStore from './store/modules/global/filters/select';

Vue.use(PortalVue);
Vue.use(VueResponsiveComponents);

// CASL/permissions
Vue.use(abilitiesPlugin, ability);

Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
Vue.component('v-popover', VPopover);
Vue.use(filters);

Vue.use(Snotify, {
  global: {
    preventDuplicates: true
  },
  toast: {
    position: SnotifyPosition.centerTop,
    timeout: 5000,
    showProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true
  }
});

Vue.use(VModal, { dialog: true });
library.add(
  faCheck,
  faHamburger,
  faArrowDown,
  faArrowUp,
  faSignInAlt,
  faEdit,
  faTrash,
  faPlusSquare,
  faQuestionCircle,
  faExclamationTriangle,
  faCalendarAlt,
  faFilter,
  faAngleDown,
  faSearch,
  faExclamationCircle,
  faInfoCircle
);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Idle Timeout
export const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3000, // 3 seconds
  startAtIdle: false
});

Vue.config.productionTip = false;

/* **********
  Give Vue access to Scss media query breakpoints
  * https://github.com/eduardoboucas/include-media-export
********** */
Vue.prototype.$breakpoints = breakpoints;

/* **********
  Axios Global Configurations
  * https://github.com/axios/axios
********** */
Vue.prototype.$http = Axios;
Axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
Axios.defaults.withCredentials = true;

function standardErrorResponseHandler(err) {
  const networkErrorMessage = 'A network error occurred.';
  if (
    Object.prototype.hasOwnProperty.call(err.config, 'errorHandle') &&
    err.config.errorHandle === false
  ) {
    return Promise.reject(err);
  }

  // Unauthorized
  if (err.response && err.response.status == 401) {
    store.dispatch('auth/logout', 'auto').then(() => {
      store.commit('auth/setLogoutReason', 'generic');
      router.push({ name: 'Login' });
    });
  }
  if (err.response && err.response.status == 404) {
    router.push({ name: 'NotFound' });
  }
  // EULA hasn't been accepted
  else if (err.response && err.response.status == 417) {
    router.push({ name: 'EndUserLicenseAgreement' });
  } else if (err.response && err.response.status >= 500) {
    Vue.prototype.$snotify.error(networkErrorMessage);
    return Promise.reject(networkErrorMessage);
  } else if (err.response && err.response.status === 400) {
    Vue.prototype.$snotify.error(err.response.data.message);
    return Promise.reject(err.response.data.message);
  } else if (!err.response) {
    Vue.prototype.$snotify.error(networkErrorMessage);
    return Promise.reject(networkErrorMessage);
  } else {
    const finalError = err.response.data.errors;
    if (finalError && typeof finalError === 'object') {
      for (const error in finalError) {
        Vue.prototype.$snotify.error(finalError[error]);
      }
    } else {
      Vue.prototype.$snotify.error(
        !finalError
          ? 'Something went wrong. Please try again later.'
          : finalError
      );
    }
    return Promise.reject(err.response.data.errors);
  }
}

Axios.interceptors.response.use(
  (response) => response,
  standardErrorResponseHandler
);

const token = Vue.$storage.get('token', false);
if (token) {
  Axios.defaults.headers.common.Authorization = token;
}
Axios.defaults.headers.common['Access-Control-Allow-Origin'] =
  window.location.origin;

Vue.component('loader', loader);

Vue.mixin({
  data() {
    return {
      breakpoints: {
        greaterThanXs: null,
        greaterThanSm: null,
        greaterThanMd: null,
        greaterThanLg: null,
        greaterThanXl: null
      },
      crcaColors: crcaColors
    };
  },
  directives: {
    clickoutside: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
        document.body.addEventListener('touchstart', el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
        document.body.removeEventListener('touchstart', el.clickOutsideEvent);
      },
      stopProp(event) {
        event.stopPropagation();
      }
    }
  },
  methods: {
    // a bit of a hack to make our tooltips availible to jqw datatables
    tooltipJqwBind: Vue.directive('tooltip').bind,
    tooltipJqwUnbind: Vue.directive('tooltip').unbind,
    tooltipJqwUpdate: Vue.directive('tooltip').update,
    breakpointUpdateGlobal() {
      this.breakpoints.greaterThanXs = this.$breakpoints.greaterThan('xs');
      this.breakpoints.greaterThanSm = this.$breakpoints.greaterThan('sm');
      this.breakpoints.greaterThanMd = this.$breakpoints.greaterThan('md');
      this.breakpoints.greaterThanLg = this.$breakpoints.greaterThan('lg');
      this.breakpoints.greaterThanXl = this.$breakpoints.greaterThan('xl');
    },
    magicModule(modNameSpace, module) {
      if (!this.$store.hasModule(modNameSpace)) {
        this.$store.registerModule(modNameSpace, module);
      }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/getIsLoggedIn'];
    },
    currentHospital() {
      return this.$store.getters['auth/getCurrentHospital'];
    }
  },
  mounted() {
    this.magicModule('logOutLoaderStore', selectStore);
  },
  created() {
    this.breakpointUpdateGlobal();
    window.addEventListener('resize', this.breakpointUpdateGlobal);
  },
  destroyed() {
    window.removeEventListener('resize', this.breakpointUpdateGlobal);
  }
});

window.vm = new Vue({
  router,
  store,
  render: (h) => h(App),
  methods: {
    // TODO: When we refactor to cards for these list views, we shouldn't need these hacks any longer and can move this into the proper componets and further reduce the main.js file size.
    // These are generic functions to use with JQW table renderers
    // configString should be json AND escaped - escape(json({...}))
    genericDelete(configString) {
      let config = JSON.parse(unescape(configString));

      if (config.type == 'population') {
        this.$snotify.confirm(
          'Removing this population cannot be undone.',
          'Are you sure?',
          {
            buttons: [
              {
                text: 'Delete it!',
                action: (toast) => {
                  this.$store
                    .dispatch(
                      'populationManagement/deleteSavedPopulation',
                      config.id
                    )
                    .then(() => {
                      this.$snotify.success('Population deleted!');
                      this.$router.push({ name: 'PopulationManagement' });
                    })
                    .finally(() => {
                      this.$snotify.remove(toast.id);
                    });
                },
                bold: false
              },
              {
                text: 'Get Me Outa Here!',
                action: (toast) => this.$snotify.remove(toast.id)
              }
            ],
            backdrop: 0.5
          }
        );
      } else if (config.type == 'druggroup') {
        this.$snotify.confirm(
          'Removing this Medication Group cannot be undone.',
          'Are you sure?',
          {
            buttons: [
              {
                text: 'Delete it!',
                action: (toast) => {
                  this.$store
                    .dispatch('medicationGroupsManagement/delete', config.id)
                    .then(() => {
                      this.$snotify.success('Medication Group deleted!');
                    })
                    .finally(() => {
                      this.$snotify.remove(toast.id);
                    });
                },
                bold: false
              },
              {
                text: 'Get Me Outa Here!',
                action: (toast) => this.$snotify.remove(toast.id)
              }
            ]
          }
        );
      }
    }
  }
}).$mount('#app');
