const productTitle =
  '<span class="uppercase">POP-Builder R<span class="lowercase">X</span><span v-if="tm">&trade;</span></span>'; // This is not how I want to do this, but ran out of time for this round.

export default [
  {
    path: '/pop-builder-rx/manage',
    name: 'PopulationManagement',
    component: () =>
      import(
        /* webpackChunkName: "manage-pops" */ '../views/auth/popbuilder/PopulationManagement'
      ),
    meta: {
      title: 'Populations',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'popBuilderRxTm',
      navSection: 'PopulationManagement',
      productTitle: productTitle,
      permission: {
        action: 'manage',
        subject: 'population-builder-rx-populations'
      }
    }
  },
  // redirect for bookmarks to saved pops
  // we can reuse name:OldPage and this format to create a forwarding information page.
  // for a direct redirect only path and redirect are needed
  {
    path: '/pop-builder-rx/manage/pop/:popid?',
    name: 'OldPage',
    component: () =>
      import(
        /* webpackChunkName: "external" */ '../views/noauth/layout/NothingToSee.vue'
      ),
    meta: {
      title: 'Forwarding...',
      hideTitleHeader: true,
      newLocationName: 'PopulationBuilder',
      layout: 'MainApp',
      productTitle: 'Trisus Medication Compare'
    }
  },
  {
    path: '/pop-builder-rx/build/:popid?',
    name: 'PopulationBuilder',
    component: () =>
      import(
        /* webpackChunkName: "builder" */ '../views/auth/populations_v2/BuilderLayoutv2.vue'
      ),
    meta: {
      title: 'Build a Population',
      navPath: '/pop-builder-rx/build',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'popBuilderRxTm',
      navSection: 'PopulationBuilder',
      productTitle: productTitle,
      hideTitleHeader: true,
      permission: {
        name: 'Build',
        operator: 'and',
        rules: [
          {
            action: 'see',
            subject: 'population-builder-rx-hospital-population-statistics'
          },
          {
            action: 'see',
            subject: 'population-builder-rx-hospital-population'
          }
        ]
      }
    }
  },
  {
    path: '/pop-builder-rx/compare',
    name: 'PopulationCompare',
    component: () =>
      import(
        /* webpackChunkName: "compare" */ '../views/auth/populations_v2/Compare.vue'
      ),
    meta: {
      title: 'Compare Populations',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'popBuilderRxTm',
      navSection: 'PopulationCompare',
      productTitle: productTitle,
      permission: {
        operator: 'and',
        rules: [
          {
            action: 'see',
            subject: 'population-builder-rx-index-population-statistics-data'
          },
          {
            action: 'see',
            subject: 'population-builder-rx-hospital-population-statistics'
          },
          {
            action: 'see',
            subject: 'population-builder-rx-index-population'
          },
          {
            action: 'manage',
            subject: 'population-builder-rx-populations'
          },
          {
            action: 'see',
            subject: 'population-builder-rx-hospital-population'
          }
        ]
      }
    }
  },
  {
    path: '/pop-builder-rx/medication-group/manage/create',
    name: 'MedicationGroupCreate',
    component: () =>
      import(
        /* webpackChunkName: "create" */ '../views/auth/popbuilder/MedicationGroupCreate'
      ),
    meta: {
      title: 'Create Medication Group',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'popBuilderRxTm',
      navSection: 'MedicationGroupManageCards',
      productTitle: productTitle,
      permission: {
        action: 'manage',
        subject: 'population-builder-rx-drug-groupings'
      }
    }
  },
  {
    path: '/pop-builder-rx/medication-group/manage/group/:groupId',
    name: 'MedicationGroupEdit',
    component: () =>
      import(
        /* webpackChunkName: "med-group" */ '../views/auth/popbuilder/MedicationGroupCreate'
      ),
    meta: {
      title: 'Edit Medication Group',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'popBuilderRxTm',
      navSection: 'MedicationGroupManageCards',
      productTitle: productTitle,
      permission: {
        action: 'manage',
        subject: 'population-builder-rx-drug-groupings'
      }
    }
  },
  {
    path: '/pop-builder-rx/medication-group/manage',
    name: 'MedicationGroupManageCards',
    component: () =>
      import(
        /* webpackChunkName: "manage-med-groups" */ '../views/auth/popbuilder/MedicationGroupCardsManage'
      ),
    meta: {
      title: 'Medication Groups',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'popBuilderRxTm',
      navSection: 'MedicationGroupManageCards',
      productTitle: productTitle,
      permissionName: '',
      permission: {
        action: 'manage',
        subject: 'population-builder-rx-drug-groupings'
      }
    }
  },
  {
    path: '/pop-builder-rx/diagnosis-group/manage',
    name: 'DiagnosisGroupsManagement',
    component: () =>
      import(
        /* webpackChunkName: "manage-med-groups" */ '../views/auth/popbuilder/DiagnosisGroupsManagement'
      ),
    meta: {
      title: 'Diagnosis Groups',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'popBuilderRxTm',
      navSection: 'DiagnosisGroupsManagement',
      productTitle: productTitle,
      permissionName: '',
      permission: {
        action: 'manage',
        subject: 'population-builder-rx-diagnosis-groups'
      }
    }
  },
  {
    path: '/pop-builder-rx/diagnosis-group/manage/create',
    name: 'DiagnosisGroupCreate',
    component: () =>
      import(
        /* webpackChunkName: "create" */ '../views/auth/popbuilder/DiagnosisGroupCreate'
      ),
    meta: {
      title: 'Create Diagnosis Group',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'popBuilderRxTm',
      navSection: 'DiagnosisGroupsManagement',
      productTitle: productTitle,
      permission: {
        action: 'manage',
        subject: 'population-builder-rx-diagnosis-groups'
      }
    }
  },
  {
    path: '/pop-builder-rx/diagnosis-group/manage/group/:groupId',
    name: 'DiagnosisGroupEdit',
    component: () =>
      import(
        /* webpackChunkName: "med-group" */ '../views/auth/popbuilder/DiagnosisGroupCreate'
      ),
    meta: {
      title: 'Edit Diagnosis Group',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'popBuilderRxTm',
      navSection: 'DiagnosisGroupsManagement',
      productTitle: productTitle,
      permission: {
        action: 'manage',
        subject: 'population-builder-rx-diagnosis-groups'
      }
    }
  },
  {
    path: '/pop-builder-rx',
    name: 'Population Builder Rx',
    meta: {
      title: 'Population Builder Rx',
      requiresAuth: true,
      layout: 'MainApp',
      module: 'popBuilderRxTm',
      productTitle: productTitle
    }
  }
];
