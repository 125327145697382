export const filterArray = function (array, filters) {
  const filterKeys = Object.keys(filters);

  if (array && Array.isArray(array)) {
    return array.filter((item) => {
      // validates all filter criteria
      return filterKeys.every((key) => {
        // ignores non-function predicates
        if (typeof filters[key] !== 'function') {
          return true;
        }
        return filters[key](item[key]);
      });
    });
  }

  // If you don't send me a good array, give you your own junk back
  return array;
};
