import sumBy from 'lodash/sumBy';
import sum from 'lodash/sum';

export const weightedAvgLos = (population = []) => {
  const weights = [];
  if (population.length > 0) {
    // extract avgAge times the count from each of the filtered population items
    population.forEach((item) => {
      if (item.avgLos && item.count) {
        weights.push(item.avgLos * item.count);
      }
    });
  } else {
    return null;
  }

  // divide the sum of the weights by the sum of the counts
  return sum(weights) / sumBy(population, 'count');
};
