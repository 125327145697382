import sumBy from 'lodash/sumBy';
import sum from 'lodash/sum';

export const weightedAvg = (items = [], itemAvgProp, itemTotalCountProp) => {
  const weights = [];
  if (items.length > 0) {
    // extract avgAge times the count from each of the filtered population items
    items.forEach((item) => {
      if (item[itemAvgProp] && item[itemTotalCountProp]) {
        weights.push(item[itemAvgProp] * item[itemTotalCountProp]);
      }
    });
  } else {
    return null;
  }

  // divide the sum of the weights by the sum of the counts
  return sum(weights) / sumBy(items, itemTotalCountProp);
};
