import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import varianceRoutes from './variance_reports';
import popBuilderRxtmRoutes from './pop_builder_rxtm';

import { checkEULA, checkPagePermission } from '../js/utilities/permissions';

Vue.use(VueRouter);

const baseRoutes = [
  {
    path: '/',
    name: 'Welcome',
    component: () =>
      import(/* webpackChunkName: "landing" */ '../views/auth/Welcome.vue'),
    meta: {
      title: 'Welcome to Trisus Medication Compare',
      requiresAuth: true,
      layout: 'Landing',
      productTitle: 'Trisus Medication Compare'
    }
  },
  {
    path: '/eula',
    name: 'EndUserLicenseAgreement',
    component: () =>
      import(/* webpackChunkName: "landing" */ '../views/auth/EULA.vue'),
    meta: {
      title: 'End User License Agreement Terms',
      requiresAuth: true,
      layout: 'MainApp',
      productTitle: 'Trisus Medication Compare'
    }
  },
  {
    path: '/eula-reset',
    name: 'EndUserLicenseAgreementReset',
    component: () =>
      import(/* webpackChunkName: "landing" */ '../views/auth/EULA.vue'),
    meta: {
      title: 'End User License Agreement',
      requiresAuth: true,
      layout: 'MainApp',
      productTitle: 'Trisus Medication Compare'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/noauth/pages/Login.vue'),
    meta: {
      title: 'Login',
      layout: 'Landing',
      productTitle: 'Trisus Medication Compare'
    }
  },
  {
    path: '/loginToken',
    name: 'LoginToken',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '../views/noauth/pages/LoginToken.vue'
      ),
    meta: {
      title: 'LoginToken',
      requiresAuth: false,
      layout: 'Landing',
      productTitle: 'Trisus Medication Compare'
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: () =>
      import(
        /* webpackChunkName: "external" */ '../views/noauth/layout/NothingToSee.vue'
      ),
    meta: {
      title: 'Page Not Found',
      hideTitleHeader: true,
      layout: 'MainApp',
      productTitle: 'Trisus Medication Compare'
    }
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () =>
      import(
        /* webpackChunkName: "external" */ '../views/noauth/layout/NothingToSee.vue'
      ),
    meta: {
      title: 'Unauthorized',
      hideTitleHeader: true,
      layout: 'MainApp',
      productTitle: 'Trisus Medication Compare'
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () =>
      import(
        /* webpackChunkName: "external" */ '../views/noauth/pages/PrivacyPolicy.vue'
      ),
    meta: {
      title: 'Privacy Policy',
      layout: 'MainApp',
      productTitle: 'Trisus Medication Compare'
    }
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: () =>
      import(
        /* webpackChunkName: "external" */ '../views/noauth/pages/TermsOfService.vue'
      ),
    meta: {
      title: 'Terms of Service',
      layout: 'MainApp',
      productTitle: 'Trisus Medication Compare'
    }
  }
];

const routes = baseRoutes.concat(varianceRoutes, popBuilderRxtmRoutes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// auth route guard
router.beforeEach((to, from, next) => {
  // This will reset permissions if the user navigates from outside of the SPA
  //    (reload, back button, etc)
  store.dispatch('auth/setPermissions');

  // If the page requires being logged in...
  if (to.meta.requiresAuth) {
    store.dispatch('auth/checkLoginStatus').then((isLoggedIn) => {
      if (!isLoggedIn) {
        // Take me back to the login page if I am not logged in
        store.dispatch('auth/logout', 'auto').then(() => {
          store.commit('auth/setReturnToPath', from.path);
          router.push({ name: 'Login' });
        });
      } else {
        // If I haven't accepted the EULA yet and am not already on the EULA page
        if (to.name != 'EndUserLicenseAgreement' && !checkEULA()) {
          router.push({ name: 'EndUserLicenseAgreement' });
        }

        // If I'm resetting my EULA
        else if (to.name == 'EndUserLicenseAgreementReset') {
          store.dispatch('auth/resetEula').then((res) => {
            store.dispatch('auth/logout').then(() => {
              router.push({ name: 'Login' });
            });
          });
        }

        // Is this the variance report landing page?
        else if (to.meta.title == 'Variance Reports') {
          // Find the first variance report I have permission to access
          for (let i in varianceRoutes) {
            // If there is no permissions, then I must have access
            if (!varianceRoutes[i].meta.permission) {
              next(varianceRoutes[i].path);
              return;
            } else if (checkPagePermission(varianceRoutes[i].meta.permission)) {
              next(varianceRoutes[i].path);
              return;
            }
          }
        }
        // Is this the pop builder landing page?
        else if (to.meta.title == 'Population Builder Rx') {
          // Find the first variance report I have permission to access
          for (let i in popBuilderRxtmRoutes) {
            // If there is no permissions, then I must have access
            if (!popBuilderRxtmRoutes[i].meta.permission) {
              next(popBuilderRxtmRoutes[i].path);
              return;
            } else if (
              checkPagePermission(popBuilderRxtmRoutes[i].meta.permission)
            ) {
              next(popBuilderRxtmRoutes[i].path);
              return;
            }
          }
        }
        // Do I have permission to view this page?
        else if (
          to.meta.permission &&
          !checkPagePermission(to.meta.permission)
        ) {
          next('/unauthorized');
        } else {
          next();
        }
      }
    });
  }
  // If I am logged in and I'm on the login page...
  else if (store.getters['auth/getIsLoggedIn'] && to.name === 'Login') {
    // ... take me into the product
    next('/');
  } else {
    next();
  }
});

// web page titles
router.afterEach((to) => {
  document.title = to.meta.title;
});

export default router;
