export const percentOfSum = (orig, target = 100) => {
  var i = orig.length;
  var j = 0;
  var total = 0;
  var change;
  var newVals = [];
  var next;
  var factor1;
  var factor2;
  var len = orig.length;
  var marginOfErrors = [];

  // map original values to new array
  while (i--) {
    if (typeof orig[i] !== 'number' || isNaN(orig[i])) {
      console.warn('Please make sure all your data consists of real numbers.');
      return orig; // return out because we can't compute this correctly
    } else {
      // map the good data
      total += newVals[i] = Math.round(orig[i]);
    }
  }
  var filteredValuesNew = newVals.filter(function (val) {
    return val;
  });

  if (!filteredValuesNew.length) {
    // nothing to calc - jump on out and do nothing with the array
    return orig;
  }

  change = total < target ? 1 : -1;

  while (total !== target) {
    // select number that will be less affected by change determined
    // in terms of itself e.g. Incrementing 10 by 1 would mean
    // an error of 10% in relation to itself.
    for (i = 0; i < len; i++) {
      next = i === len - 1 ? 0 : i + 1;

      factor2 = errorFactor(orig[next], newVals[next] + change);
      factor1 = errorFactor(orig[i], newVals[i] + change);

      if (factor1 > factor2) {
        j = next;
      }
    }

    newVals[j] += change;
    total += change;
  }

  for (i = 0; i < len; i++) {
    marginOfErrors[i] = newVals[i] && Math.abs(orig[i] - newVals[i]) / orig[i];
  }

  for (i = 0; i < len; i++) {
    for (j = 0; j < len; j++) {
      if (j === i) continue;

      var roundUpFactor =
        errorFactor(orig[i], newVals[i] + 1) +
        errorFactor(orig[j], newVals[j] - 1);
      var roundDownFactor =
        errorFactor(orig[i], newVals[i] - 1) +
        errorFactor(orig[j], newVals[j] + 1);
      var sumMargin = marginOfErrors[i] + marginOfErrors[j];

      if (roundUpFactor < sumMargin) {
        newVals[i] = newVals[i] + 1;
        newVals[j] = newVals[j] - 1;
        marginOfErrors[i] =
          newVals[i] && Math.abs(orig[i] - newVals[i]) / orig[i];
        marginOfErrors[j] =
          newVals[j] && Math.abs(orig[j] - newVals[j]) / orig[j];
      }

      if (roundDownFactor < sumMargin) {
        newVals[i] = newVals[i] - 1;
        newVals[j] = newVals[j] + 1;
        marginOfErrors[i] =
          newVals[i] && Math.abs(orig[i] - newVals[i]) / orig[i];
        marginOfErrors[j] =
          newVals[j] && Math.abs(orig[j] - newVals[j]) / orig[j];
      }
    }
  }

  function errorFactor(oldNum, newNum) {
    return Math.abs(oldNum - newNum) / oldNum;
  }

  return newVals;
};
