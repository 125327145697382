import { reactive } from '@vue/composition-api';

let state = reactive({
  columnToPopulation: []
});

const cleanColumnPopulationMap = function () {
  for (let i = state.columnToPopulation.length - 1; i >= 0; i--) {
    if (state.columnToPopulation[i] === null) {
      state.columnToPopulation.splice(i, 1);
    }
  }
};
const getColumnPopulationMap = function () {
  return state.columnToPopulation;
};

const getPopulationAtColumn = function (c) {
  return state.columnToPopulation[c];
};

const isPopulationUsedInAnyColumn = function (p) {
  for (let i in state.columnToPopulation) {
    if (state.columnToPopulation[i] == p) {
      return true;
    }
  }

  return false;
};

const setColumnToPopulation = function (c, p) {
  state.columnToPopulation[c] = p;
};

const clearColumnPopulation = function (c) {
  state.columnToPopulation[c] = null;
};

export default {
  cleanColumnPopulationMap,
  getColumnPopulationMap,
  getPopulationAtColumn,
  isPopulationUsedInAnyColumn,
  setColumnToPopulation,
  clearColumnPopulation
};
