import axios from 'axios';

const getDefaultState = () => {
  return {
    savedList: [],
    singleSavedList: {},
    loading: false,
    dirty: true
  };
};

const state = getDefaultState();

const getters = {
  getSavedList: (state) => state.savedList,
  getSingleSavedList: (state) => state.singleSavedList,
  getSingleSavedListNDCDetails: (state) => state.singleSavedListNDCDetails,
  getLoading: (state) => state.loading,
  getDirty: (state) => state.dirty
};

const mutations = {
  setSavedList(state, payload) {
    state.savedList = payload;
  },
  setSingleSavedList(state, payload) {
    state.singleSavedList = payload;
  },
  setSingleSavedListNDCDetails(state, payload) {
    state.singleSavedListNDCDetails = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setDirty(state, payload) {
    state.dirty = payload;
  }
};

const actions = {
  getSavedList({ commit }) {
    commit('setLoading', true);
    return new Promise((resolve, reject) => {
      axios({
        url: '/filter-service/drug-groupings' // new URL
      })
        .catch((err) => {
          reject(err);
        })
        .then((resp) => {
          commit('setSavedList', resp.data.data);
          resolve();
        })
        .finally(() => {
          commit('setLoading', false);
        });
    });
  },
  getSingleSavedList({ commit }, id) {
    commit('setLoading', true);
    return new Promise((resolve, reject) => {
      axios({
        url: '/filter-service/drug-groupings/' + id // new URL
      })
        .catch((err) => {
          reject(err);
        })
        .then((resp) => {
          commit('setSingleSavedList', resp.data.data);
          resolve(resp.data.data);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    });
  },
  getSingleSavedListNDCDetails({ commit }, id) {
    commit('setLoading', true);
    return new Promise((resolve, reject) => {
      axios({
        url: '/data-service/ndcsByDrugGroupIdv2/?searchString=' + id // new URL
      })
        .catch((err) => {
          reject(err);
        })
        .then((resp) => {
          commit('setSingleSavedListNDCDetails', resp.data);
          resolve(resp.data);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    });
  },
  delete({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/filter-service/drug-groupings/' + id, // new URL
        method: 'DELETE'
      })
        .catch((err) => {
          reject(err);
        })
        .then(() => {
          dispatch('getSavedList');
          resolve();
        });
    });
  },
  save({ dispatch }, { data, id }) {
    return new Promise((resolve, reject) => {
      if (data.MedicationGroupName === null) {
        reject('Please name your Medication Group.');
        return;
      }
      let url, method;
      if (id) {
        url = '/filter-service/drug-groupings/' + id;
        method = 'PUT';
      } else {
        url = '/filter-service/drug-groupings';
        method = 'POST';
      }
      axios({
        url: url,
        data: data,
        method: method
      })
        .catch((err) => {
          reject(err);
        })
        .then((resp) => {
          dispatch('getSavedList', id);
          resolve(resp.data);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
