import sumBy from 'lodash/sumBy';
import { filterArray } from '@/js/utilities';

export const genders = [
  {
    description: 'Male',
    code: '1'
  },
  {
    description: 'Female',
    code: '2'
  },
  {
    description: 'Unknown',
    code: '0'
  }
];

// TODO: Consider refactoring once new workflows are completed
export const calculateGenderData = (
  { visits, patients } = {},
  options = { sumBy: 'count' }
) => {
  let visitCounts = {};
  let patientCounts = {};

  if (visits && visits.length > 0) {
    visitCounts = {
      maleVisitCount: sumBy(
        filterArray(visits, {
          gender: (gender) => [1].includes(gender)
        }),
        'count'
      ),
      femaleVisitCount: sumBy(
        filterArray(visits, {
          gender: (gender) => [2].includes(gender)
        }),
        'count'
      )
    };
  } else {
    visitCounts = { maleVisitCount: null, femaleVisitCount: null };
  }

  if (patients && patients.length > 0) {
    patientCounts = {
      malePatientCount: sumBy(
        filterArray(patients, {
          gender: (gender) => [1].includes(gender)
        }),
        options.sumBy
      ),
      femalePatientCount: sumBy(
        filterArray(patients, {
          gender: (gender) => [2].includes(gender)
        }),
        options.sumBy
      )
    };
  } else {
    patientCounts = { malePatientCount: null, femalePatientCount: null };
  }

  return {
    ...visitCounts,
    ...patientCounts
  };
};
