import ability from './ability';

export const checkEULA = function () {
  return ability.can('pass', 'eula');
};

export const checkPagePermission = function (p) {
  if (p.action) {
    return ability.can(p.action, p.subject);
  } else if (p.operator) {
    for (let i in p.rules) {
      if (
        p.operator == 'and' &&
        !ability.can(p.rules[i].action, p.rules[i].subject)
      ) {
        return false;
      } else if (
        p.operator == 'or' &&
        ability.can(p.rules[i].action, p.rules[i].subject)
      ) {
        return true;
      }
    }

    // If we got here
    if (p.operator == 'and') return true;
    else if (p.operator == 'or') return false;
  }

  return false;
};
