const dollar = (val, places = 2) => {
  return '$' + commas(precision(val, places));
};

const dollarLargeAbbr = (val) => {
  return '$' + largeNumberAbbr(val);
};

const commas = (val) => {
  if (val === null || val === undefined) return;

  var split = val.toString().split('.');
  split[0] = split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return split.join('.');
};

const precision = (val, places = 3) => {
  if (val === null || val === undefined) return;
  if (places < 0) return val;

  let pow = Math.pow(10, places);
  let num = Math.round(val * pow) / pow;
  let numSplit = num.toString().split('.');
  let decimal = numSplit[1] ? numSplit[1] : '';

  if (places === 0) return Math.round(val);

  while (decimal.length < places) {
    decimal = decimal + '0';
  }

  if (isFinite(numSplit[0])) {
    return numSplit[0] + '.' + decimal;
  }
  return numSplit[0];
};

const largeNumberAbbr = (num, places = 1) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(num)) >= 1.0e9
    ? precision(Math.abs(Number(num)) / 1.0e9, places) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(num)) >= 1.0e6
    ? precision(Math.abs(Number(num)) / 1.0e6, places) + 'M'
    : // We want to keep more of the K level, so setting at 5 zeros
    // to trigger the rounding with 3 zeros
    Math.abs(Number(num)) >= 1.0e5
    ? precision(Math.abs(Number(num)) / 1.0e3, places) + 'K'
    : commas(Math.round(Math.abs(Number(num))));
};

export const formatNumber = (val, options) => {
  let format = 'default';
  if (options) {
    format = options.format;
  }
  switch (format) {
    case 'dollar':
      return dollar(val, options.places);
    case 'dollarLargeAbbr':
      return dollarLargeAbbr(val);
    case 'commas':
      return commas(val);
    case 'precision':
      return precision(val, options.places);
    case 'largeNumberAbbr':
      return largeNumberAbbr(val, options.places);
    default:
      return val;
  }
};
