const state = function () {
  return {
    value: null
  };
};

const getters = {
  getValue: (state) => state.value
};

const mutations = {
  setValue(state, payload) {
    state.value = payload;
  },
  clearOnLogout(state, payload) {
    state.value = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  // actions,
  mutations
};
