import axios from 'axios';

const getDefaultState = () => {
  return {
    totalOpportunity: 0,
    loadingTotalOpportunity: false,
    ndcByOpportunity: [],
    loadingNdcByOpportunity: false,
    ndcPurchaseDetails: [],
    loadingNdcPurchaseDetails: false,
    ndcCardId: [],
    retriveNdcIds: []
  };
};
const state = getDefaultState();

const getters = {
  getNdcCardId: (state) => state.getNdcCardId,
  getRetriveNdcIds: (state) => state.getRetriveNdcIds
};
const mutations = {
  setTotalOpportunity(state, payload) {
    state.totalOpportunity = payload;
  },
  setLoadingTotalOpportunity(state, payload) {
    state.loadingTotalOpportunity = payload;
  },
  setNdcByOpportunity(state, payload) {
    state.ndcByOpportunity = payload;
  },
  addResultsToNdcByOpportunity(state, payload) {
    const ndcs = new Set(state.ndcByOpportunity.map((d) => d.ndc));
    state.ndcByOpportunity = [
      ...state.ndcByOpportunity,
      ...payload.filter((d) => !ndcs.has(d.ndc))
    ];
  },
  setLoadingNdcByOpportunity(state, payload) {
    state.loadingNdcByOpportunity = payload;
  },
  setNdcPurchaseDetails(state, payload) {
    state.ndcPurchaseDetails = payload;
  },
  addResultsToNdcPurchaseDetails(state, payload) {
    const ndcs = new Set(
      state.ndcPurchaseDetails.map((d) => d.description.ndc)
    );
    state.ndcPurchaseDetails = [
      ...state.ndcPurchaseDetails,
      ...payload.filter((d) => !ndcs.has(d.description.ndc))
    ];
  },
  setLoadingNdcPurchaseDetails(state, payload) {
    state.loadingNdcPurchaseDetails = payload;
  },
  setNdcCardId(state, payload) {
    state.ndcCardId = payload;
  },
  setRetriveNdcIds(state, payload) {
    state.retriveNdcIds = payload;
  }
};
const actions = {
  requestTotalOpportunity({ commit }, { requestData }) {
    commit('setLoadingTotalOpportunity', true);
    return new Promise((resolve, reject) => {
      axios({
        url: '/data-service/drugpricingopportunity/', // totalOpportunity
        params: requestData
      })
        .then((resp) => {
          if (!resp.data.totalOpportunity) {
            commit('setTotalOpportunity', '0');
            resolve('No opportunity was found.'); // to do, figure out how to express this in a useful way
          } else {
            commit('setTotalOpportunity', resp.data.totalOpportunity);
            resolve(resp.data.totalOpportunity);
          }
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoadingTotalOpportunity', false);
        });
    });
  },
  requestNdcByOpportunity({ commit }, { requestData }) {
    commit('setLoadingNdcByOpportunity', true);
    return new Promise((resolve, reject) => {
      axios({
        url: '/data-service/ndcByOpportunity/',
        params: requestData
      })
        .then((resp) => {
          // commits need to happen template side because we may need to overwrite store object or add to it
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoadingNdcByOpportunity', false);
        });
    });
  },
  requestNdcPurchaseDetails({ commit }, { requestData }) {
    commit('setLoadingNdcPurchaseDetails', true);
    return new Promise((resolve, reject) => {
      axios({
        url: '/data-service/ndcPurchaseDetails/', // ndcPurchasingDetail
        params: requestData
      })
        .then((resp) => {
          // commits need to happen template side because we may need to overwrite store object or add to it
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          commit('setLoadingNdcPurchaseDetails', false);
        });
    });
  },
  requestNdcCardId({ commit }, requestData) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/data-service/hideNdcCards/', // New API URL for hide Cards
        params: {
          accountId: requestData.accountId,
          hospitalIds: requestData.hospitalIds
        },
        data: JSON.stringify(requestData.dataArray),
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json;charset=utf-8'
        }
      })
        .then((resp) => {
          // commits need to happen template side because we may need to overwrite store object or add to it
          commit('setNdcCardId', resp.data.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
        });
    });
  },
  requestRetriveNdcIds({ commit }, requestData) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/data-service/retrieveNdcCards/', // ndcPurchasingDetail
        params: {
          accountId: requestData.accountId
        }
      })
        .then((resp) => {
          // commits need to happen template side because we may need to overwrite store object or add to it
          commit('setRetriveNdcIds', resp.data);

          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
