<template>
  <div
    id="loaderContainer"
    v-if="fullScreen ? open : true"
    :class="{ 'fullscreen-loader-background': fullScreen, inline: !fullScreen }"
    :style="[zIndexStyle]"
    role="status"
  >
    <!-- Default slot can be used to change the loader content to something custom. -->
    <slot>
      <dna-loader
        v-if="loaderStyle === 'dna' || !loaderStyle"
        :style="[widthStyle]"
      />
      <bar-loader
        v-else-if="loaderStyle === 'bar' || !loaderStyle"
        :style="[widthStyle]"
      />
      <donut-loader
        v-else-if="loaderStyle === 'donut' || !loaderStyle"
        :style="[widthStyle]"
      />
    </slot>
    <div class="off-screen-text">
      <!-- Update offscreen text used by screen readers. -->
      <slot name="offscreenText">Loading content.</slot>
    </div>
  </div>
</template>

<script>
  /* Lazy Loading SVGs */
  const dnaLoader = () => import('../../components/loaders/dnaLoader');
  const barLoader = () => import('../../components/loaders/barLoader');
  const donutLoader = () => import('../../components/loaders/donutLoader');
  /**
   * @name Loader
   * Component to create a loading animation. Loader is imported globally as 'loader'.
   * ## To Use
   * Add to template. Pass in props as needed to configure.
   * ```html
   * <loader />
   * ```
   *
   * If fullScreen is true, create a ref name to access open and close functions.
   * ```html
   * <loader fullScreen ref="miamiLoader" />
   * ```
   * ```javascript
   * export default {
   *   methods: {
   *      openLoader () {
   *        this.$refs.miamiLoader.openLoader()
   *      },
   *      closeLoader () {
   *        this.$refs.miamiLoader.closeLoader()
   *      }
   *   }
   * }
   * ```
   * Enjoy!
   */
  export default {
    name: 'Loader',
    components: {
      dnaLoader,
      barLoader,
      donutLoader
    },
    props: {
      /**
       * Open state on int load of componet.
       */
      startOpen: {
        type: Boolean,
        default: false
      },
      /**
       * Present as full screen modal-style loading animation which blocks interaction with the page.
       */
      fullScreen: {
        type: Boolean,
        default: false
      },
      /**
       * Graphic style of loader svg. Choose between DNA, Bar, or Donut. DNA is default and should be used in most situations. Bar and donut are for charted bar or donut data.
       */
      loaderStyle: {
        type: String,
        default: 'dna',
        validator: (value) => ['dna', 'bar', 'donut'].indexOf(value) > -1
      },
      /**
       * Change the default z-index if needed.
       */
      zIndex: {
        type: Number,
        default: 10000
      },
      /**
       * Change the default width of the loading svg graphic. Can be any legit css width value.
       */
      loaderWidth: {
        type: String,
        default: '100px',
        validator: (value) =>
          new RegExp(
            /(d+)?(px|em|ex|%|in|cn|mm|pt|pc|rem|vw|vh|vmin|vmax+)/gim
          ).test(value)
      }
    },
    data() {
      return {
        /**
         * Provides a way to update the inline style z-index of the loader container based on the zIndex prop.
         * @initialValue {'z-index': this.zIndex}
         * @type Object
         */
        zIndexStyle: {
          'z-index': this.zIndex
        },
        /**
         * Provides a way to update the inline style width of the loader based on the loaderWidth prop.
         * @initialValue {width: this.loaderWidth}
         * * @type Object
         */
        widthStyle: {
          width: this.loaderWidth
        },
        /**
         * Provides a way to start with the loader open.
         * @initialValue {width: this.startOpen}
         * * @type Object
         */
        open: this.startOpen
      };
    },
    methods: {
      /**
       * Open the modal loader. Set a ref to call from parent componet.
       */
      openLoader() {
        this.open = true;
      },
      /**
       * Close the modal loader. Set a ref to call from parent componet.
       */
      closeLoader() {
        this.open = false;
      }
    }
  };
</script>
