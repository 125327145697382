import { defaultDateRange } from '@/data';
import { reactive } from '@vue/composition-api';

const baselinePopulation = {};
const baselineDateRange = reactive(defaultDateRange);

export default {
  baselinePopulation,
  baselineDateRange
};
