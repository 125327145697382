import sumBy from 'lodash/sumBy';
import { filterArray } from '@/js/utilities';

export const calculateReadmissionCounts = (
  population,
  options = { sumBy: 'count' }
) => {
  // moved this default setting into the body of the function because
  // if null is passed in as the population argument it overrides the default array
  // this creates more needless checks to prevent js errors
  if (!population) {
    population = [];
  }
  const filteredPop = filterArray(population, {
    readmissionIndicator: (readmissionIndicator) =>
      readmissionIndicator === true
  });

  return sumBy(filteredPop, options.sumBy);
};
